import { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { useAuth, useFunctions, useSigninCheck } from "reactfire"
import type {
  RecaptchaVerifier as TRecaptchaVerifier,
  AuthError,
  User,
} from "firebase/auth"
import {
  PhoneAuthProvider,
  AuthErrorCodes,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth"
import { $Values, Optional } from "utility-types"
import {
  setAnanlyticsIdentity,
  trackEvent,
  TrackingEvents,
} from "@cashbook/util-tracking"
import { setLoggerIdentity } from "@cashbook/util-logging"
import { httpsCallable } from "firebase/functions"
import {
  clearExternalStorage,
  removeSyncStoredItem,
} from "@cashbook/data-store/storage"
import { clearFirestoreCache } from "@cashbook/data-store/utils"
import { isPhoneNumberIndian } from "@cashbook/util-general"
import { toast } from "react-hot-toast"

export type TAuthUser = {
  displayName?: string
  email?: string | null
  emailVerified?: boolean
  isAnonymous: boolean
  phoneNumber?: string | null
  photoURL?: string | null
  providerId: "firebase"
  uid: string
  fcmTokens?: Array<string>
  desktopNotificationsAllowed?: boolean
  metadata: {
    creationTime?: string
    lastSignInTime?: string
  }
}

type TSocialAuth = {
  status: string
  message: string
  maxAttempts: number
  attemptsLeft: number
}

export function useLogout() {
  const auth = useAuth()

  return async function logout(type: "fromThisDevice" | "fromAllDevices") {
    await auth.signOut()
    clearFirestoreCache()
    clearExternalStorage()
    setAnanlyticsIdentity(null)
    setLoggerIdentity(null)
    trackEvent(TrackingEvents.LOGOUT, {
      type,
    })
    clearFirestoreCache()
    const keysToBeRemovedOnLogout = [
      "pdf_options",
      "pdf_other_options",
      "ajs_user_traits",
      "dismissedBanners",
      "isNewUser",
      "bookRolesAndPermissions",
      "staffInfoTransitioned",
      "visitedAddParty",
      "cashbooksFilter",
      "deletedBusiness",
      "categorySuggestions",
    ]
    keysToBeRemovedOnLogout.forEach((key) => {
      removeSyncStoredItem(key)
    })
  }
}

export function useLogoutFromAllDevices() {
  const auth = useAuth()
  const fns = useFunctions()
  const [state, setState] = useState<"init" | "loading" | "done" | "error">(
    "init"
  )
  const [error, setError] = useState<Error | undefined>(undefined)
  async function logoutFromAllDevices() {
    setState("loading")
    try {
      httpsCallable(
        fns,
        "logOutAllDevices"
      )({}).then(async (data) => {
        await auth.signOut()
        clearFirestoreCache()
        clearExternalStorage()
        setAnanlyticsIdentity(null)
        setLoggerIdentity(null)
        clearFirestoreCache()
        trackEvent(TrackingEvents.LOGOUT, {
          type: "fromAllDevices",
        })
        const keysToBeRemovedOnLogout = [
          "pdf_options",
          "pdf_other_options",
          "ajs_user_traits",
          "dismissedBanners",
          "isNewUser",
          "bookRolesAndPermissions",
          "staffInfoTransitioned",
          "visitedAddParty",
          "cashbooksFilter",
        ]
        keysToBeRemovedOnLogout.forEach((key) => {
          removeSyncStoredItem(key)
        })
        setState("done")
      })
    } catch (e) {
      const err = e as Error
      setState("error")
      setError(err)
    }
  }
  return {
    error,
    state,
    logoutFromAllDevices,
  }
}

type State = {
  secondsRemainingToResend: number
  phoneVerificationId: string | null
  attempts: number
  emailOtpAttempts: number
  sendingVerificationCode: boolean
  whatsAppOtpAttempts: number
}
export function useSendVerificationCode() {
  const auth = useAuth()
  const fns = useFunctions()
  const [
    {
      attempts,
      emailOtpAttempts,
      whatsAppOtpAttempts,
      secondsRemainingToResend,
      phoneVerificationId,
      sendingVerificationCode,
    },
    dispatch,
  ] = useReducer(
    (state: State, action: Optional<State>) => ({ ...state, ...action }),
    {
      secondsRemainingToResend: 0,
      phoneVerificationId: null,
      attempts: 0,
      emailOtpAttempts: 0,
      whatsAppOtpAttempts: 0,
      sendingVerificationCode: false,
    }
  )
  const [captchaVerifier, setCaptchaVerifier] =
    useState<TRecaptchaVerifier | null>(null)

  const sendVerificationCode = useCallback(
    async (
      phoneNumber: string,
      config?: { secondsRemainingToResend?: number }
    ): Promise<string> => {
      if (!captchaVerifier) {
        throw new Error("Missing captcha. Please refresh the page.")
      }
      const isPhoneIndian = isPhoneNumberIndian(phoneNumber)
      const authMethod = isPhoneIndian ? "sms_from_cloud" : "sms_from_firebase"
      try {
        dispatch({
          attempts: attempts + 1,
          secondsRemainingToResend: config?.secondsRemainingToResend || 30,
          sendingVerificationCode: true,
        })
        if (authMethod === "sms_from_cloud") {
          const { data } = await httpsCallable<
            { phoneNumber: string },
            TSocialAuth
          >(
            fns,
            "sendSmsOtp"
          )({
            phoneNumber,
          })
          dispatch({
            sendingVerificationCode: false,
          })
          return data.status
        }
        const phoneVerificationId = await new PhoneAuthProvider(
          auth
        ).verifyPhoneNumber(phoneNumber, captchaVerifier)
        dispatch({
          phoneVerificationId,
          sendingVerificationCode: false,
        })
        return phoneVerificationId
      } catch (error) {
        if (authMethod === "sms_from_firebase") throw resolveAuthErrors(error)
        throw resolveCloudFunctionAuthErrors(error)
      }
    },
    [captchaVerifier, attempts, auth, fns]
  )

  const createAuthCredentials = useCallback(
    (code: string | number) => {
      if (!phoneVerificationId) {
        throw new Error("Phone verification id is missing")
      }
      return PhoneAuthProvider.credential(phoneVerificationId, String(code))
    },
    [phoneVerificationId]
  )

  const verifyOtp = useCallback(
    async (phoneNumber: string, code: string): Promise<User> => {
      const isPhoneIndian = isPhoneNumberIndian(phoneNumber)
      const authMethod = isPhoneIndian ? "sms_from_cloud" : "sms_from_firebase"
      try {
        if (authMethod === "sms_from_cloud") {
          const {
            data: { token },
          } = await httpsCallable<
            { phoneNumber: string; otp: string },
            { token: string }
          >(
            fns,
            "verifySmsOtp"
          )({
            phoneNumber,
            otp: code,
          })
          if (!token.length) {
            throw new Error("Something went wrong. Try again later.")
          }
          const { user } = await signInWithCustomToken(auth, token)
          return user
        }
        const credentials = createAuthCredentials(code)
        const { user } = await signInWithCredential(auth, credentials)
        return user
      } catch (e) {
        if (authMethod === "sms_from_firebase") throw resolveAuthErrors(e)
        throw resolveCloudFunctionAuthErrors(e)
      }
    },
    [auth, createAuthCredentials, fns]
  )

  const sendVerificationCodeViaWhatsApp = useCallback(
    async (
      phoneNumber: string,
      config?: { secondsRemainingToResend?: number }
    ): Promise<TSocialAuth> => {
      if (!captchaVerifier) {
        throw new Error("Missing captcha. Please refresh the page.")
      }
      try {
        const { data } = await httpsCallable<
          { phoneNumber: string },
          TSocialAuth
        >(
          fns,
          "sendOtp"
        )({
          phoneNumber,
        })
        dispatch({
          attempts: whatsAppOtpAttempts + 1,
          secondsRemainingToResend: config?.secondsRemainingToResend || 30,
          sendingVerificationCode: true,
        })
        if (data.message !== "sent") {
          throw new Error("Something went wrong. Try again later.")
        }
        return data
      } catch (e) {
        throw resolveCloudFunctionAuthErrors(e)
      } finally {
        dispatch({
          sendingVerificationCode: false,
        })
      }
    },
    [captchaVerifier, whatsAppOtpAttempts, fns]
  )

  const sendVerificationCodeUsingEmail = useCallback(
    async (
      email: string,
      config?: { secondsRemainingToResend?: number }
    ): Promise<TSocialAuth> => {
      if (!captchaVerifier) {
        throw new Error("Missing captcha. Please refresh the page.")
      }
      try {
        const { data } = await httpsCallable<{ email: string }, TSocialAuth>(
          fns,
          "sendEmailOtp"
        )({
          email,
        })
        dispatch({
          emailOtpAttempts: emailOtpAttempts + 1,
          secondsRemainingToResend: config?.secondsRemainingToResend || 30,
          sendingVerificationCode: true,
        })
        if (data.status !== "success") {
          throw new Error("Something went wrong. Try again later.")
        }
        return data
      } catch (e) {
        throw resolveCloudFunctionAuthErrors(e)
      } finally {
        dispatch({
          sendingVerificationCode: false,
        })
      }
    },
    [captchaVerifier, emailOtpAttempts, fns]
  )

  const resendVerificationCode = useCallback(
    async (phoneNumber: string) => {
      const data = await sendVerificationCode(phoneNumber, {
        secondsRemainingToResend: 30 * (attempts || 1),
      })
      return data
    },
    [sendVerificationCode, attempts]
  )

  const resendVerificationCodeViaEmail = useCallback(
    async (email: string) => {
      const data = await sendVerificationCodeUsingEmail(email, {
        secondsRemainingToResend: 30 * (emailOtpAttempts || 1),
      })
      return data
    },
    [sendVerificationCodeUsingEmail, emailOtpAttempts]
  )

  const resendVerificationCodeViaWhatsApp = useCallback(
    async (phoneNumber: string) => {
      const data = await sendVerificationCodeViaWhatsApp(phoneNumber, {
        secondsRemainingToResend: 30 * (whatsAppOtpAttempts || 1),
      })
      return data
    },
    [sendVerificationCodeViaWhatsApp, whatsAppOtpAttempts]
  )

  const verifyOtpViaEmail = useCallback(
    async (email: string, otp: string): Promise<string> => {
      if (!captchaVerifier) {
        throw new Error("Missing captcha. Please refresh the page.")
      }
      try {
        const {
          data: { token },
        } = await httpsCallable<
          { email: string; otp: string },
          { token: string }
        >(
          fns,
          "verifyEmailOtp"
        )({
          email,
          otp,
        })
        if (!token.length) {
          throw new Error("Something went wrong. Try again later.")
        }
        return token
      } catch (e) {
        throw resolveCloudFunctionAuthErrors(e)
      }
    },
    [captchaVerifier, fns]
  )

  const verifyOtpViaWhatsApp = useCallback(
    async (phoneNumber: string, otp: string): Promise<string> => {
      if (!captchaVerifier) {
        throw new Error("Missing captcha. Please refresh the page.")
      }
      try {
        const {
          data: { token, verified },
        } = await httpsCallable<
          { phoneNumber: string; otp: string },
          { token: string; verified: boolean }
        >(
          fns,
          "verifyOtp"
        )({
          phoneNumber,
          otp,
        })
        if (!verified) {
          throw new Error(
            "You have entered an invalid OTP. Try again with the right one."
          )
        }
        return token
      } catch (e) {
        throw resolveCloudFunctionAuthErrors(e)
      }
    },
    [captchaVerifier, fns]
  )

  const secondsRemainingToResendTimerRef = useRef<NodeJS.Timer | undefined>(
    undefined
  )
  useEffect(() => {
    if (secondsRemainingToResendTimerRef.current) {
      clearInterval(secondsRemainingToResendTimerRef.current)
      secondsRemainingToResendTimerRef.current = undefined
    }
    if (secondsRemainingToResend <= 0) {
      return
    }
    secondsRemainingToResendTimerRef.current = setInterval(() => {
      const n = secondsRemainingToResend - 1
      dispatch({
        secondsRemainingToResend: n,
      })
      if (n <= 0 && secondsRemainingToResendTimerRef.current)
        clearInterval(secondsRemainingToResendTimerRef.current)
    }, 1000)
    return () =>
      secondsRemainingToResendTimerRef.current
        ? clearInterval(secondsRemainingToResendTimerRef.current)
        : undefined
  }, [secondsRemainingToResend])
  const resetVerification = useCallback(() => {
    dispatch({
      phoneVerificationId: null,
      secondsRemainingToResend: 0,
    })
  }, [])

  return {
    attempts,
    emailOtpAttempts,
    verifyOtp,
    sendVerificationCode,
    resendVerificationCode,
    verifyOtpViaEmail,
    verifyOtpViaWhatsApp,
    sendVerificationCodeUsingEmail,
    resendVerificationCodeViaEmail,
    phoneVerificationId,
    resetVerification,
    createAuthCredentials,
    secondsRemainingToResend,
    secondsRemainingToResendStr: secondsToString(secondsRemainingToResend),
    captchaVerifier,
    setCaptchaVerifier,
    sendingVerificationCode,
    sendVerificationCodeViaWhatsApp,
    resendVerificationCodeViaWhatsApp,
  }
}

export function useSocialAuthentications() {
  const auth = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const [captchaVerifier, setCaptchaVerifier] =
    useState<TRecaptchaVerifier | null>(null)

  const googleAuthentication = useCallback(async () => {
    if (!captchaVerifier) {
      toast.error("Missing captcha. Please refresh the page.")
      return
    }
    setLoading(true)
    try {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ prompt: "select_account" })
      const result = await signInWithPopup(auth, provider)
      const isNewUser = getAdditionalUserInfo(result)?.isNewUser
      trackEvent(TrackingEvents.SOCIAL_AUTHENTICATION_DONE, {
        method: "google",
        flow: isNewUser ? "signup" : "login",
      })
      setLoading(false)
      return
    } catch (e) {
      const error = resolveAuthErrors(e)
      toast.error(error.message)
      setLoading(false)
    }
  }, [auth, captchaVerifier])
  return {
    loading,
    captchaVerifier,
    setCaptchaVerifier,
    googleAuthentication,
  }
}

function secondsToString(seconds: number) {
  let h = 0
  let m = Math.floor(seconds / 60)
  if (m >= 60) {
    h = Math.floor(m / 60)
    m = m % 60
  }
  const s = seconds % 60
  return (h > 0 ? [h] : [])
    .concat([m, s])
    .map((u) => (u < 10 ? `0${u}` : u))
    .join(":")
}

export function resolveAuthErrors(e: unknown) {
  const error = e as AuthError
  const errorCode = error.code as $Values<typeof AuthErrorCodes>
  switch (errorCode) {
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
    case AuthErrorCodes.MISSING_PHONE_NUMBER:
      return new Error("Please provide a valid phone number")
    case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
      return new Error("Invalid captcha. Please refresh the page and try again")
    case AuthErrorCodes.INVALID_CODE:
      return new Error("The OTP you entered is incorrect.")
    case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
      return new Error(
        "Verification tries exceeded. Please try after some time"
      )
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      return new Error("Network Error. Please try after some time")
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      return new Error("Please select a valid account!")
    default:
      if (errorCode) {
        return new Error(
          `[${errorCode}]: Can not verify. Please try after some time`
        )
      }
      return new Error(error.message)
  }
}

export function resolveCloudFunctionAuthErrors(e: unknown) {
  const error = e as Error
  const errorCode = error.stack?.replace("FirebaseError: ", "")
  switch (errorCode) {
    case "attempts-exhausted":
      return new Error(
        "You have used all of the attempts. Please try again after some time."
      )
    case "SMS_OTP.NON_INDIAN_PHONE_NUMBER":
      return new Error(
        "Please verify this number with WhatsApp or log in with email."
      )
    case "error-sending":
      return new Error("We could not send OTP on this number")
    case "phoneNumber not present.":
    case "SMS_OTP.PHONE_NUMBER_REQUIRED":
    case "SMS_OTP.INVALID_PHONE_NUMBER":
      return new Error("Please enter a valid phone number.")
    case "SMS_OTP.OTP_REQUIRED":
    case "SMS_OTP.INVALID_OTP":
    case "EMAIL_OTP.INVALID_OTP":
    case "otp not present.":
      return new Error("The OTP you entered is incorrect.")
    case "EMAIL_OTP.INVALID_EMAIL":
    case "EMAIL_OTP.EMAIL_REQUIRED":
    case "EMAIL_OTP.NOT_FOUND_OR_NOT_VERIFIED":
      return new Error("Please enter valid email.")
    default:
      return new Error(
        error.message ||
          "Something went wrong. Please try again after some time."
      )
  }
}

export function IfAuthenticated({ children }: { children: React.ReactNode }) {
  const { status, data: signInCheckResult } = useSigninCheck()
  if (status === "loading") {
    return null
  }
  if (signInCheckResult.signedIn === true) {
    return <>{children}</>
  }
  return null
}

export function useCheckUsersExists() {
  const fns = useFunctions()
  const checkPhoneExists = useCallback(
    async (phoneNumber: string) => {
      try {
        const { data } = await httpsCallable<
          { phoneNumber: string },
          {
            isTaken: boolean
            status: string
          }
        >(
          fns,
          "checkIfPhoneIsTaken"
        )({
          phoneNumber,
        })
        return data.isTaken
      } catch (e) {
        throw resolveAuthErrors(e)
      }
    },
    [fns]
  )

  const checkEmailExists = useCallback(
    async (email: string) => {
      try {
        const { data } = await httpsCallable<
          { email: string },
          {
            isTaken: boolean
            status: string
          }
        >(
          fns,
          "checkIfEmailIsTaken"
        )({
          email,
        })
        return data.isTaken
      } catch (e) {
        throw resolveAuthErrors(e)
      }
    },
    [fns]
  )

  return {
    checkPhoneExists,
    checkEmailExists,
  }
}
