import {
  getServerTimestamp,
  intervalToDuration,
  timeStampToDate,
} from "@cashbook/util-dates"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useFormik } from "formik"
import { useCallback, useMemo, useRef, useState } from "react"
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from "reactfire"
import { $PropertyType, $Values } from "utility-types"
import { Optional } from "utility-types"
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import type { CollectionReference } from "firebase/firestore"
import {
  b64toBlob,
  isPhoneNumberIndian,
  saveBlobAs,
  sleep,
  isVisitorIndian,
} from "@cashbook/util-general"

import { Timestamp } from "firebase/firestore"
import { logError } from "@cashbook/util-logging"
import toast from "react-hot-toast"

export type TBookEntryField = {
  uuid: string
  name: string
  phoneNumber?: string
  type?: T_AVAILABLE_PARTY_TYPES
}

export type TBookEntryFields = Array<TBookEntryField>

export type TBookParties = TBookEntryFields
export type TBookCategories = TBookEntryFields
export type TBookPaymentModes = TBookEntryFields
export type TBookCustomField = {
  name: string
  order?: number
  required: boolean
  type?: "text"
  fieldName?: string
}

export type TBookCustomFields = {
  [key: string]: TBookCustomField
}

export type TBookPreferences = {
  partyDisabled: boolean
  categoriesDisabled: boolean
  paymentModesDisabled: boolean
  allowedBackdatedEntries?: -1 | 0 | 1 // undefined means 1
  hideBalancesAndReports?: boolean // undefined means 0
  hideEntriesByOthers?: boolean
  categoriesRequired?: boolean
  paymentModesRequired?: boolean
}

export type PARTY_PREFERENCES = {
  [key: string]: {
    sendSMS: boolean
    sendWhatsApp: boolean
  }
}

export type TBook = {
  id: string
  name: string
  updatedAt?: Timestamp
  createdAt?: Timestamp
  createdBy?: string
  updatedBy?: string
  /**
   * Partner members on book level
   */
  partners?: Array<string>
  /**
   * Admin members for this book
   */
  admins: Array<string>
  /**
   * Members with editing rights
   */
  editors: Array<string>
  /**
   * All members in the book
   */
  sharedWith: Array<string>

  customFields?: TBookCustomFields

  /**
   * Parties available on this book
   */
  parties?: TBookParties
  partyPreferences?: PARTY_PREFERENCES
  partiesById: { [uuid: string]: TBookParties[number] }
  /**
   * Payment modes available on this book
   */
  paymentModes?: TBookPaymentModes
  paymentModesById: { [uuid: string]: TBookPaymentModes[number] }
  /**
   * Categories available in this book
   */
  categories?: TBookCategories
  categoriesById: { [uuid: string]: TBookCategories[number] }
  /**
   * Preferences settings for this book
   */
  preferences?: TBookPreferences

  ownerId: string
  totalCashIn?: number
  totalCashOut?: number
  pendingInvitationsCount?: number
  numEntries?: number
  businessId: string
}

export type TInvolvedUser = {
  id: string
  name: string
  email?: string
  phoneNumber?: string
}

function useBooksCollection() {
  const store = useFirestore()
  return collection(store, "CashBooks") as CollectionReference<TBook>
}

export function useBookDocument(bookId: string) {
  const booksCollection = useBooksCollection()
  return doc(booksCollection, bookId)
}

export function useInvolvedUsersCollection(bookId: string) {
  const bookDoc = useBookDocument(bookId)
  return collection(
    bookDoc,
    "InvolvedUsers"
  ) as CollectionReference<TInvolvedUser>
}

export function useInvolvedUserDocument(
  bookId: string,
  involvedUsersId: string
) {
  const collection = useInvolvedUsersCollection(bookId)
  return doc(collection, involvedUsersId)
}

export function useAddBook() {
  const booksCollection = useBooksCollection()
  const { data: user } = useUser()
  return useCallback(
    async function addBook(data: {
      name: string
      ownerId: string
      businessId: string
    }) {
      const bookRef = doc(booksCollection)
      await setDoc(bookRef, {
        name: data.name.trim(),
        createdBy: user ? user.uid : "",
        createdAt: getServerTimestamp(),
        sharedWith: user ? [user.uid] : [],
        editors: user ? [user.uid] : [],
        admins: user ? [user.uid] : [],
        ownerId: data.ownerId,
        businessId: data.businessId,
      } as never)
      sleep(1000)
      return bookRef.id
    },
    [booksCollection, user]
  )
}

export function useBooks() {
  const booksCollection = useBooksCollection()
  const { data: user } = useUser()
  const booksQuery = query(
    booksCollection,
    where("sharedWith", "array-contains", user?.uid),
    orderBy("createdAt", "desc")
  )
  const { data: books } = useFirestoreCollectionData(booksQuery, {
    idField: "id",
  })
  return {
    books,
  }
}

type TBookSearchParams = {
  q?: string
}

export function useBooksSearch() {
  const { books: baseBooks } = useBooks()
  const {
    values: params,
    handleChange: handleParamsChange,
    setFieldValue,
  } = useFormik<TBookSearchParams>({
    initialValues: { q: "" },
    onSubmit: () => undefined,
  })
  const hasAppliedFilters = useMemo(() => {
    return Boolean(params.q?.trim())
  }, [params])
  const books = useMemo(() => {
    if (!params.q?.trim()) return baseBooks
    const q = params.q
    return baseBooks.filter((b) =>
      b.name.toLowerCase().includes(q.trim().toLowerCase())
    )
  }, [baseBooks, params])
  return {
    allBooks: baseBooks,
    books,
    params,
    hasAppliedFilters,
    handleParamsChange,
    setParamValue: setFieldValue,
  }
}

function useUpdateBook(bookId: string) {
  const bookeDoc = useBookDocument(bookId)
  const { data: user } = useUser()
  return async function updateBook(data: { name: string }) {
    await updateDoc(bookeDoc, {
      ...data,
      name: data.name.trim(),
      updatedAt: getServerTimestamp(),
      updatedBy: user?.uid,
    } as never)
    trackEvent(TrackingEvents.RENAME_CASH_BOOK)
  }
}

function useUpdatePartyPreferencesInBook(book: TBook) {
  const bookDoc = useBookDocument(book.id)
  return useCallback(
    async (partyPreferences: Optional<PARTY_PREFERENCES>) => {
      await updateDoc(bookDoc, {
        partyPreferences: {
          ...book.partyPreferences,
          ...partyPreferences,
        },
      } as never)
    },
    [bookDoc, book]
  )
}

export function useDeleteBook(bookId: string) {
  const bookDoc = useBookDocument(bookId)
  const { data: user } = useUser()
  return async function deleteBook() {
    await updateDoc(bookDoc, {
      deletedBy: user?.uid,
    } as never)
    // let's wait for 100ms before deleting the book document
    await sleep(100)
    await deleteDoc(bookDoc)
  }
}

export function useUpdateBookPreferences(book: TBook) {
  const bookDoc = useBookDocument(book.id)
  return useCallback(
    async (preferences: Optional<TBookPreferences>) => {
      await updateDoc(bookDoc, {
        preferences: {
          ...book.preferences,
          ...preferences,
        },
      } as never)
    },
    [bookDoc, book]
  )
}

export type TBookMember = TInvolvedUser & {
  uid: string
  role: $Values<typeof ROLES_AND_PERMISSIONS>
}

const EMPTY_ARRAY_OF_PARTIES: $PropertyType<TBook, "parties"> = []
const EMPTY_ARRAY_OF_CATEGORIES: $PropertyType<TBook, "categories"> = []
const EMPTY_ARRAY_OF_PAYMENT_MODES: $PropertyType<TBook, "paymentModes"> = []

/**
 * This hook will hold a reference to the book even after book deletion
 */
export function useEnsuredBook(bookId: string) {
  const bookeDoc = useBookDocument(bookId)
  const { data: bookData } = useFirestoreDocData(bookeDoc, { idField: "id" })
  // Create a ref of current data and pass it down.
  // This allows us to handle the book deletion
  const bookRef = useRef(bookData)
  let isDeleted = false
  if (bookData && bookData.name) {
    // always keep the data in sync
    bookRef.current = bookData
  } else {
    isDeleted = true
  }
  if (!bookRef.current) {
    throw new Error("Book not found")
  }
  return { book: bookRef.current, isDeleted }
}

export function useBook(bookId: string) {
  const { book, isDeleted } = useEnsuredBook(bookId)
  const involvedUsersCollection = useInvolvedUsersCollection(bookId)
  const { data: user } = useUser()
  const { data: involvedUsersData } = useFirestoreCollectionData(
    query(involvedUsersCollection, orderBy("name", "asc")),
    { idField: "id" }
  )
  const involvedUsers: Array<TInvolvedUser> = useMemo(() => {
    if (!user) return []
    if (!involvedUsersData.length)
      return [
        {
          id: user.uid,
          name: user.displayName || "You",
          email: user.email || "",
          phoneNumber: user.phoneNumber || "",
        },
      ]
    return involvedUsersData
  }, [involvedUsersData, user])
  const sharedWithIds = useMemo(
    () => book.sharedWith || (user ? [user.uid] : []),
    [book, user]
  )
  const parties = book.parties || EMPTY_ARRAY_OF_PARTIES
  const paymentModes = book.paymentModes || EMPTY_ARRAY_OF_PAYMENT_MODES
  const categories = book.categories || EMPTY_ARRAY_OF_CATEGORIES
  const membersWithRolesAndPermissions: Array<TBookMember> = useMemo(() => {
    const resolveUsersFromInvolvedUsers = (
      users: Array<string>
    ): Array<TInvolvedUser & { uid: string }> => {
      return (users || [])
        .map((a) => {
          const data = involvedUsers.find((u) => u.id === a)
          if (data) {
            return {
              ...data,
              uid: a,
            }
          }
          if (user && a === user.uid) {
            // resolve it from auth user
            return {
              id: a,
              name: user.displayName || "",
              phoneNumber: user.phoneNumber,
              email: user.email,
              uid: a,
            }
          }
          return undefined
        })
        .filter((a): a is TInvolvedUser & { uid: string } => Boolean(a))
    }
    const sharedWithUsers = resolveUsersFromInvolvedUsers(sharedWithIds)
    const members = sharedWithUsers.map((user) => {
      return {
        ...user,
        role: getRoleDetailsForMember(book, user.uid),
      }
    })
    // now sort the members
    members.sort((a, b) => {
      // keep the authenticated member at the top
      if (user && a.uid === user.uid) return -1
      if (user && b.uid === user.uid) return 1
      // now prefer owners, partners, then admins, then editors and last to viewers
      const roles = ["viewer", "editor", "admin", "partner", "owner"]
      const aRoleIndex = roles.indexOf(a.role.id)
      const bRoleIndex = roles.indexOf(b.role.id)
      if (aRoleIndex !== bRoleIndex) {
        // prefer the owner roles
        return aRoleIndex > bRoleIndex ? -1 : 1
      }
      // same role, sort by name
      return a.name > b.name ? 1 : -1
    })
    return members
  }, [involvedUsers, user, book, sharedWithIds])
  const authMemberDetails: TBookMember = useMemo(() => {
    const member = membersWithRolesAndPermissions.find(
      (member) => member.uid === user?.uid
    )
    if (member) return member
    // This book might not be a group book
    return {
      uid: user?.uid || "id",
      id: user?.uid || "id",
      email: user?.email as "",
      name: user?.displayName || "CashBook User",
      phoneNumber: user?.phoneNumber as "",
      // assign the role
      role:
        membersWithRolesAndPermissions.length <= 1
          ? getRoleDetails(membersWithRolesAndPermissions[0].role.id)
          : getRoleDetails("viewer"),
    }
  }, [user, membersWithRolesAndPermissions])
  // some helpful utilities
  const deleteBook = useDeleteBook(bookId)
  const updateBook = useUpdateBook(bookId)
  const updatePartyPreferences = useUpdatePartyPreferencesInBook(book)

  const allowedBackdatedEntriesForEditor =
    book?.preferences?.allowedBackdatedEntries === undefined
      ? -1
      : book?.preferences?.allowedBackdatedEntries
  const hideBalancesAndReportsForEditor = Boolean(
    book?.preferences?.hideBalancesAndReports
  )
  const hideEntriesByOthers = Boolean(book?.preferences?.hideEntriesByOthers)

  /**
   * Check if the currently authenticated user has requested permission(s)
   */
  const checkIfAuthenticatedMemberCan = useCallback(
    (permission: BOOK_PERMISSIONS, ...permissions: Array<BOOK_PERMISSIONS>) =>
      checkIfMemberCan(book, authMemberDetails, permission, ...permissions),
    [book, authMemberDetails]
  )

  /**
   * Check if the currently authenticated user has any of requested permissions
   */
  const checkIfAuthenticatedMemberCanDoOneOf = useCallback(
    (permission: BOOK_PERMISSIONS, ...permissions: Array<BOOK_PERMISSIONS>) =>
      [permission]
        .concat(permissions)
        .some((permission) =>
          checkIfMemberCan(book, authMemberDetails, permission)
        ),
    [authMemberDetails, book]
  )

  const getMemberInfoForId = useCallback(
    (memberId: string | undefined | null) => {
      if (!memberId) return null
      const member = involvedUsers.find((user) => user.id === memberId)
      if (!member) return null
      return {
        ...member,
        isAuthMember: member.id === user?.uid,
      }
    },
    [involvedUsers, user]
  )
  const getMemberInfoWithRoleForId = useCallback(
    (memberId: string | undefined | null) => {
      if (!memberId) return null
      const member = involvedUsers.find((user) => user.id === memberId)
      if (!member) return null
      return {
        ...member,
        role: getRoleDetailsForMember(book, memberId),
        isAuthMember: member.id === user?.uid,
      } as unknown as TBookMember
    },
    [involvedUsers, book, user]
  )
  const partiesById = useMemo(() => {
    return (parties || []).reduce<{
      [uuid: string]: $PropertyType<Required<TBook>, "parties">[number]
    }>((partiesById, party) => {
      partiesById[party.uuid] = party
      return partiesById
    }, {})
  }, [parties])

  let bookOwner = {} as TBookMember
  membersWithRolesAndPermissions.forEach((member) => {
    if (member.role.id === "owner") {
      bookOwner = Object.assign(bookOwner, member)
      return
    }
  })

  const paymentModesById = useMemo(() => {
    return (paymentModes || []).reduce<{
      [uuid: string]: $PropertyType<Required<TBook>, "paymentModes">[number]
    }>((paymentModesById, paymentMode) => {
      paymentModesById[paymentMode.uuid] = paymentMode
      return paymentModesById
    }, {})
  }, [paymentModes])
  const categoriesById = useMemo(() => {
    return (categories || []).reduce<{
      [uuid: string]: $PropertyType<Required<TBook>, "categories">[number]
    }>((categoriesById, category) => {
      categoriesById[category.uuid] = category
      return categoriesById
    }, {})
  }, [categories])
  book.partiesById = partiesById
  book.categoriesById = categoriesById
  book.paymentModesById = paymentModesById
  return {
    book,
    isDeleted,
    involvedUsers,
    bookOwner,
    members: membersWithRolesAndPermissions,
    updateBook,
    deleteBook,
    checkIfAuthenticatedMemberCan,
    checkIfAuthenticatedMemberCanDoOneOf,
    authMemberDetails,
    getMemberInfoForId,
    updatePartyPreferences,
    getMemberInfoWithRoleForId,
    isShared: isSharedBook(book),
    allowedBackdatedEntriesForEditor,
    hideBalancesAndReportsForEditor,
    hideEntriesByOthers,
    sharedWithIds,
  }
}

export function isSharedBook(book: TBook): boolean {
  if (!book || !book.sharedWith) return false
  return book.sharedWith.length > 1
}

export enum BOOK_PERMISSIONS {
  ADD_CASH_IN_OUT = "ADD_CASH_IN_OUT",
  ADD_MEMBER = "ADD_MEMBER",
  DELETE_ALL_ENTRIES = "DELETE_ALL_ENTRIES",
  DELETE_BOOK = "DELETE_BOOK",
  DUPLICATE_BOOK = "DUPLICATE_BOOK",
  DELETE_ENTRY = "DELETE_ENTRY",
  VIEW_NET_BALANCE = "VIEW_NET_BALANCE",
  DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS",
  EDIT_BOOK = "EDIT_BOOK",
  EDIT_ENTRY = "EDIT_ENTRY",
  REMOVE_MEMBER = "REMOVE_MEMBER",
  UPDATE_MEMBER_ROLES = "UPDATE_MEMBER_ROLES",
  VIEW_ENTRIES = "VIEW_ENTRIES",
  MOVE_ENTRIES = "MOVE_ENTRIES",
  COPY_ENTRIES = "COPY_ENTRIES",
  ADD_OPPOSITE_ENTRIES = "ADD_OPPOSITE_ENTRIES",
  UPDATE_ENTRY_FIELDS = "UPDATE_ENTRY_FIELDS",
  ADD_BACK_DATED_ENTRIES = "ADD_BACK_DATED_ENTRIES",
  // the following permission is added conditionally in the getRoleDetailsForMember function
  ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY = "ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY",
  ADD_FUTURE_DATED_ENTRIES = "ADD_FUTURE_DATED_ENTRIES",
  // following permission only allowed in log entry
  ADD_PARTY_ENTRY_FIELD = "ADD_PARTY_ENTRY_FIELD",
  CAN_CHANGE_OWNER = "CAN_CHANGE_OWNER",
  CONFIGURE_CUSTOM_FIELDS = "CONFIGURE_CUSTOM_FIELDS",

  //BL changes
  ASSIGN_ADMIN_ROLE = "ASSIGN_ADMIN_ROLE",
  MOVE_BOOK = "MOVE_BOOK",
  UPDATE_ADMIN_ROLE = "UPDATE_ADMIN_ROLE",
  REMOVE_ADMIN_MEMBER = "REMOVE_ADMIN_MEMBER",
  UPDATE_OR_RESEND_INVITE = "UPDATE_OR_RESEND_INVITE",
}

export function getRoleDetailsForMember(
  book: TBook,
  member: { id: string } | string | undefined
) {
  let role: T_AVAILABLE_ROLES = "viewer"
  if (member) {
    const id = typeof member === "string" ? member : member.id
    const ownerId = book.ownerId
    const partnerIds = book.partners || []
    const adminIds = book.admins || []
    const editorIds = book.editors || []
    switch (true) {
      case ([ownerId] || []).indexOf(id) !== -1:
        role = "owner"
        break
      case partnerIds.indexOf(id) !== -1:
        role = "partner"
        break
      case (adminIds || []).indexOf(id) !== -1:
        role = "admin"
        break
      case (editorIds || []).indexOf(id) !== -1:
        role = "editor"
        break
      default:
        role = "viewer"
    }
  }
  let details = getRoleDetails(role)
  if (
    role === "editor" &&
    (book.preferences?.hideBalancesAndReports ||
      book.preferences?.allowedBackdatedEntries === 0 ||
      book.preferences?.allowedBackdatedEntries === 1)
  ) {
    // remove the permissions from editor
    details = {
      ...details,
      permissions: details.permissions.filter((p) => {
        if (book.preferences?.hideBalancesAndReports) {
          if (
            p === BOOK_PERMISSIONS.VIEW_NET_BALANCE ||
            p === BOOK_PERMISSIONS.DOWNLOAD_REPORTS
          ) {
            return false
          }
        }
        if (
          book.preferences?.allowedBackdatedEntries === 0 &&
          (p === BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES ||
            p === BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY)
        ) {
          return false
        }
        if (
          book.preferences?.allowedBackdatedEntries === 1 &&
          p === BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES
        ) {
          return false
        }
        return true
      }),
    }
    // add the permission of ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY if provided
    if (book.preferences?.allowedBackdatedEntries === 1) {
      details.permissions.push(
        BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY
      )
    }
  }
  return details
}

export function checkIfMemberCan(
  book: TBook,
  member: TBookMember | string | undefined,
  ...permissions: Array<BOOK_PERMISSIONS>
) {
  if (!member) return false
  let memberPermissions: Array<BOOK_PERMISSIONS> = []
  if (typeof member === "string") {
    memberPermissions = getRoleDetailsForMember(book, member).permissions
  } else {
    memberPermissions = member.role.permissions
  }
  return permissions.every((p) => memberPermissions.indexOf(p) !== -1)
}

const PARTY_TYPES = {
  customer: {
    id: "customer" as const,
    title: "Customer",
  },
  supplier: {
    id: "supplier" as const,
    title: "Supplier",
  },
}

const ROLES_AND_PERMISSIONS = {
  editor: {
    id: "editor" as const,
    title: "Data Operator",
    permissions: [
      BOOK_PERMISSIONS.ADD_CASH_IN_OUT,
      BOOK_PERMISSIONS.VIEW_NET_BALANCE,
      BOOK_PERMISSIONS.DOWNLOAD_REPORTS,
      BOOK_PERMISSIONS.VIEW_ENTRIES,
      BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_PARTY_ENTRY_FIELD,
    ],
    permissionsDescription: [
      "Add Cash In or Cash Out entries",
      "View entries by everyone",
      "View net balance & download PDF or Excel",
    ],
    restrictionsDescription: ["Cannot edit entries"],
  },
  viewer: {
    id: "viewer" as const,
    title: "Viewer",
    permissions: [
      BOOK_PERMISSIONS.VIEW_NET_BALANCE,
      BOOK_PERMISSIONS.DOWNLOAD_REPORTS,
      BOOK_PERMISSIONS.VIEW_ENTRIES,
    ],
    permissionsDescription: [
      "View entries by everyone",
      "View net balance & download PDF or Excel",
    ],
    restrictionsDescription: [],
  },
  admin: {
    id: "admin" as const,
    title: "Admin",
    permissions: [
      BOOK_PERMISSIONS.ADD_CASH_IN_OUT,
      BOOK_PERMISSIONS.ADD_FUTURE_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_MEMBER,
      BOOK_PERMISSIONS.DELETE_ENTRY,
      BOOK_PERMISSIONS.VIEW_NET_BALANCE,
      BOOK_PERMISSIONS.DOWNLOAD_REPORTS,
      BOOK_PERMISSIONS.EDIT_ENTRY,
      BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES,
      BOOK_PERMISSIONS.VIEW_ENTRIES,
      BOOK_PERMISSIONS.MOVE_ENTRIES,
      BOOK_PERMISSIONS.COPY_ENTRIES,
      BOOK_PERMISSIONS.REMOVE_MEMBER,
      BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES,
      BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS,
      BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_PARTY_ENTRY_FIELD,
      BOOK_PERMISSIONS.CONFIGURE_CUSTOM_FIELDS,
    ],
    permissionsDescription: [
      "Full access to book settings & activity log",
      "Customize data operator permissions",
      "Change roles of data operator or viewer",
    ],
    restrictionsDescription: [
      "Can’t remove owners or partners",
      `Can’t delete book`,
    ],
  },
  partner: {
    id: "partner" as const,
    title: "Partner",
    permissions: [
      BOOK_PERMISSIONS.ADD_CASH_IN_OUT,
      BOOK_PERMISSIONS.ADD_FUTURE_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_MEMBER,
      BOOK_PERMISSIONS.DELETE_ALL_ENTRIES,
      BOOK_PERMISSIONS.DELETE_BOOK,
      BOOK_PERMISSIONS.DUPLICATE_BOOK,
      BOOK_PERMISSIONS.DELETE_ENTRY,
      BOOK_PERMISSIONS.VIEW_NET_BALANCE,
      BOOK_PERMISSIONS.DOWNLOAD_REPORTS,
      BOOK_PERMISSIONS.EDIT_BOOK,
      BOOK_PERMISSIONS.EDIT_ENTRY,
      BOOK_PERMISSIONS.REMOVE_MEMBER,
      BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES,
      BOOK_PERMISSIONS.VIEW_ENTRIES,
      BOOK_PERMISSIONS.MOVE_ENTRIES,
      BOOK_PERMISSIONS.COPY_ENTRIES,
      BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES,
      BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS,
      BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_PARTY_ENTRY_FIELD,
      BOOK_PERMISSIONS.CAN_CHANGE_OWNER,
      BOOK_PERMISSIONS.ASSIGN_ADMIN_ROLE,
      BOOK_PERMISSIONS.MOVE_BOOK,
      BOOK_PERMISSIONS.UPDATE_OR_RESEND_INVITE,
      BOOK_PERMISSIONS.UPDATE_ADMIN_ROLE,
      BOOK_PERMISSIONS.REMOVE_ADMIN_MEMBER,
      BOOK_PERMISSIONS.CONFIGURE_CUSTOM_FIELDS,
    ],
    permissionsDescription: [
      "View entries and download reports",
      "Add Cash In or Cash Out entries",
      "Edit and delete entries",
      "Access to all Book Settings",
      "Move or copy entries from one book to other book",
      "Access Book Activity and Entry’s Edit History",
      "Duplicate and Delete Book",
    ],
    restrictionsDescription: [],
  },
  owner: {
    id: "owner" as const,
    title: "Owner",
    permissions: [
      BOOK_PERMISSIONS.ADD_CASH_IN_OUT,
      BOOK_PERMISSIONS.ADD_FUTURE_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_MEMBER,
      BOOK_PERMISSIONS.DELETE_ALL_ENTRIES,
      BOOK_PERMISSIONS.DELETE_BOOK,
      BOOK_PERMISSIONS.DUPLICATE_BOOK,
      BOOK_PERMISSIONS.DELETE_ENTRY,
      BOOK_PERMISSIONS.VIEW_NET_BALANCE,
      BOOK_PERMISSIONS.DOWNLOAD_REPORTS,
      BOOK_PERMISSIONS.EDIT_BOOK,
      BOOK_PERMISSIONS.EDIT_ENTRY,
      BOOK_PERMISSIONS.REMOVE_MEMBER,
      BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES,
      BOOK_PERMISSIONS.VIEW_ENTRIES,
      BOOK_PERMISSIONS.MOVE_ENTRIES,
      BOOK_PERMISSIONS.COPY_ENTRIES,
      BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES,
      BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS,
      BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES,
      BOOK_PERMISSIONS.ADD_PARTY_ENTRY_FIELD,
      BOOK_PERMISSIONS.CAN_CHANGE_OWNER,
      BOOK_PERMISSIONS.ASSIGN_ADMIN_ROLE,
      BOOK_PERMISSIONS.MOVE_BOOK,
      BOOK_PERMISSIONS.UPDATE_OR_RESEND_INVITE,
      BOOK_PERMISSIONS.UPDATE_ADMIN_ROLE,
      BOOK_PERMISSIONS.REMOVE_ADMIN_MEMBER,
      BOOK_PERMISSIONS.CONFIGURE_CUSTOM_FIELDS,
    ],
    permissionsDescription: [
      "View entries and download reports",
      "Add Cash In or Cash Out entries",
      "Edit and delete entries",
      "Access to all Book Settings",
      "Move or copy entries from one book to other book",
      "Access Book Activity and Entry’s Edit History",
      "Duplicate and Delete Book",
    ],
    restrictionsDescription: [],
  },
}

export type T_AVAILABLE_ROLES = keyof typeof ROLES_AND_PERMISSIONS

export function getAllRoles() {
  return Object.keys(ROLES_AND_PERMISSIONS).map((key) => ({
    id: ROLES_AND_PERMISSIONS[key as T_AVAILABLE_ROLES].id,
    title: ROLES_AND_PERMISSIONS[key as T_AVAILABLE_ROLES].title,
  }))
}

export function getAllRolesWithPermissions() {
  return Object.keys(ROLES_AND_PERMISSIONS).map((key) => ({
    ...ROLES_AND_PERMISSIONS[key as T_AVAILABLE_ROLES],
    role: key,
  }))
}

export type T_AVAILABLE_PARTY_TYPES = keyof typeof PARTY_TYPES
export function getAllPartyTypes() {
  return Object.keys(PARTY_TYPES).map((key) => ({
    ...PARTY_TYPES[key as T_AVAILABLE_PARTY_TYPES],
    party: key,
  }))
}

export function getRoleDetails(role: keyof typeof ROLES_AND_PERMISSIONS) {
  return ROLES_AND_PERMISSIONS[role]
}

type CUSTOM_FIELD_OPS = "CREATE" | "UPDATE" | "REORDER" | "DELETE"
export function useCustomField(book: TBook) {
  const fns = useFunctions()
  const add = useCallback(
    async (
      values: { name: string; required: boolean },
      from:
        | "entryFieldSettings"
        | "chooseParty"
        | "chooseCategory"
        | "choosePaymentMode"
        | "importEntries"
    ) => {
      const { data } = await httpsCallable<
        typeof values & { operation: CUSTOM_FIELD_OPS; bookId: string },
        { field: string }
      >(
        fns,
        "configureCustomFields"
      )({
        ...values,
        bookId: book.id,
        operation: "CREATE",
      })
      trackEvent(TrackingEvents.CUSTOM_FIELD_ADDED, {
        sharedBook: isSharedBook(book),
        from,
        via: "manual",
      })
      return {
        name: values.name,
        required: values.required,
        type: "text",
        fieldName: data.field,
      } as TBookCustomFields[number]
    },
    [book, fns]
  )

  const deleteField = useCallback(
    async (values: { field?: string }) => {
      await httpsCallable<
        typeof values & { operation: CUSTOM_FIELD_OPS; bookId: string }
      >(
        fns,
        "configureCustomFields"
      )({
        ...values,
        bookId: book.id,
        operation: "DELETE",
      })
      return true
    },
    [book.id, fns]
  )

  const update = useCallback(
    async (values: { field?: string; name: string; required: boolean }) => {
      await httpsCallable<
        typeof values & { operation: CUSTOM_FIELD_OPS; bookId: string }
      >(
        fns,
        "configureCustomFields"
      )({
        ...values,
        bookId: book.id,
        operation: "UPDATE",
      })
      return true
    },
    [book.id, fns]
  )

  return {
    add,
    update,
    deleteField,
  }
}

export function useAddParty(
  book: TBook,
  from:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
    | "importParties"
) {
  const fns = useFunctions()
  return useCallback(
    async (values: {
      name: string
      type?: T_AVAILABLE_PARTY_TYPES
      phoneNumber?: string
    }) => {
      const { data: uuid } = await httpsCallable<
        typeof values & { cashbookId: string },
        { party: TBookParties[number] }
      >(
        fns,
        "createParty"
      )({
        ...values,
        cashbookId: book.id,
      })
      trackEvent(TrackingEvents.PARTY_ADDED, {
        sharedBook: isSharedBook(book),
        from,
        via: "manual",
        partyPhoneNumber: values.phoneNumber,
        partyType: values.type,
      })
      const { party } = uuid
      return {
        ...party,
      } as TBookEntryFields[number]
    },
    [fns, book, from]
  )
}

export function useAddCategory(
  book: TBook,
  from:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
    | "importEntries"
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { name: string; fromSuggestions?: boolean }) => {
      const { data: uuid } = await httpsCallable<
        { cashbookId: string; name: string },
        string
      >(
        fns,
        "createCategory"
      )({
        cashbookId: book.id,
        name: values.name,
      })
      trackEvent(TrackingEvents.CATEGORY_ADDED, {
        sharedBook: isSharedBook(book),
        from,
        fromSuggestions: values.fromSuggestions,
      })
      return { uuid, name: values.name } as TBookEntryFields[number]
    },
    [fns, book, from]
  )
}

export function useAddPaymentMode(
  book: TBook,
  from:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
    | "importEntries"
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { name: string; fromSuggestions?: boolean }) => {
      const { data: uuid } = await httpsCallable<
        typeof values & { cashbookId: string },
        string
      >(
        fns,
        "createPaymentMode"
      )({
        ...values,
        cashbookId: book.id,
      })
      trackEvent(TrackingEvents.PAYMENT_MODE_ADDED, {
        sharedBook: isSharedBook(book),
        from,
        fromSuggestions: values.fromSuggestions,
      })
      return { uuid, name: values.name } as TBookEntryFields[number]
    },
    [fns, book, from]
  )
}

export function useImportParties(book: TBook) {
  const fns = useFunctions()
  return useCallback(
    async (values: {
      parties: Array<{
        name: string
        type?: T_AVAILABLE_PARTY_TYPES
        phoneNumber?: string
      }>
      cashbookId: string
    }) => {
      await httpsCallable<typeof values, void>(
        fns,
        "createParties"
      )({
        cashbookId: values.cashbookId,
        parties: values.parties,
      })
      trackEvent(TrackingEvents.PARTIES_IMPORTED_SUCCESSFULLY, {
        sharedBook: isSharedBook(book),
        partyCount: values.parties.length,
      })
    },
    [fns, book]
  )
}

export function useImportCategories(book: TBook) {
  const fns = useFunctions()
  return useCallback(
    async (values: { names: string[]; cashbookId: string }) => {
      await httpsCallable<typeof values, void>(
        fns,
        "createCategories"
      )({
        cashbookId: values.cashbookId,
        names: values.names,
      })
      trackEvent(TrackingEvents.CATEGORIES_IMPORTED_SUCCESSFULLY, {
        sharedBook: isSharedBook(book),
        categoryCount: values.names.length,
      })
    },
    [fns, book]
  )
}

export function useImportPaymentModes(book: TBook) {
  const fns = useFunctions()
  return useCallback(
    async (values: { names: string[]; cashbookId: string }) => {
      await httpsCallable<typeof values, void>(
        fns,
        "createPaymentModes"
      )({
        cashbookId: values.cashbookId,
        names: values.names,
      })
      trackEvent(TrackingEvents.PAYMENT_MODES_IMPORTED_SUCCESSFULLY, {
        sharedBook: isSharedBook(book),
        paymentModeCount: values.names.length,
      })
    },
    [fns, book]
  )
}

export function useUpdateParty(
  book: TBook,
  from?: "entryFieldSettings" | "chooseParty"
) {
  const fns = useFunctions()
  return useCallback(
    async (values: {
      partyId: string
      name: string
      type?: T_AVAILABLE_PARTY_TYPES
      phoneNumber?: string
      taggedEntries: number
    }) => {
      const previousParty = book.partiesById[values.partyId]
      const { data: uuid } = await httpsCallable<
        typeof values & { cashbookId: string },
        string
      >(
        fns,
        "updateParty"
      )({
        ...values,
        cashbookId: book.id,
      })
      trackEvent(TrackingEvents.EDIT_PARTY, {
        nameChanged: Boolean(previousParty.name !== values.name),
        typeChanged: Boolean(previousParty.type !== values.type),
        phoneChanged: Boolean(previousParty.phoneNumber !== values.phoneNumber),
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
        from: from,
      })
      return {
        uuid,
        name: values.name,
        phoneNumber: values.phoneNumber,
        type: values.type,
      } as TBookEntryFields[number]
    },
    [fns, book, from]
  )
}

export function useUpdatePartyPhoneNumber(bookId: string) {
  const fns = useFunctions()
  return useCallback(
    async (values: { partyId: string; name: string; phoneNumber: string }) => {
      const { data: uuid } = await httpsCallable<
        typeof values & { cashbookId: string },
        string
      >(
        fns,
        "updateParty"
      )({
        ...values,
        cashbookId: bookId,
      })
      trackEvent(TrackingEvents.UPDATE_PARTY_NUMBER, {
        partyId: values.partyId,
        phoneNumber: values.phoneNumber,
        from: "chooseParty",
      })
      return uuid as string
    },
    [fns, bookId]
  )
}

export function useUpdateCategory(
  book: TBook,
  field: TBookEntryFields[number]
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { name: string; taggedEntries: number }) => {
      await httpsCallable(
        fns,
        "updateCategory"
      )({
        ...field,
        categoryId: field.uuid,
        cashbookId: book.id,
        name: values.name,
      })
      trackEvent(TrackingEvents.CATEGORY_RENAMED, {
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
      })
    },
    [fns, book, field]
  )
}

export function useUpdatePaymentMode(
  book: TBook,
  field: TBookEntryFields[number]
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { name: string; taggedEntries: number }) => {
      await httpsCallable(
        fns,
        "updatePaymentMode"
      )({
        ...field,
        paymentModeId: field.uuid,
        cashbookId: book.id,
        name: values.name,
      })
      trackEvent(TrackingEvents.PAYMENT_MODE_RENAMED, {
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
      })
    },
    [fns, book, field]
  )
}

export function useDeleteParty(book: TBook, field: TBookEntryFields[number]) {
  const fns = useFunctions()
  return useCallback(
    async (values: { taggedEntries: number }) => {
      await httpsCallable(
        fns,
        "deleteParty"
      )({
        cashbookId: book.id,
        partyId: field.uuid,
      })
      trackEvent(TrackingEvents.PARTY_DELETED, {
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
      })
    },
    [fns, book, field]
  )
}

export function useDeleteCategory(
  book: TBook,
  field: TBookEntryFields[number]
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { taggedEntries: number }) => {
      await httpsCallable(
        fns,
        "deleteCategory"
      )({
        cashbookId: book.id,
        categoryId: field.uuid,
      })
      trackEvent(TrackingEvents.CATEGORY_DELETED, {
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
      })
    },
    [fns, book, field]
  )
}

export function useDeletePaymentMode(
  book: TBook,
  field: TBookEntryFields[number]
) {
  const fns = useFunctions()
  return useCallback(
    async (values: { taggedEntries: number }) => {
      await httpsCallable(
        fns,
        "deletePaymentMode"
      )({
        cashbookId: book.id,
        paymentModeId: field.uuid,
      })
      trackEvent(TrackingEvents.PAYMENT_MODE_DELETED, {
        sharedBook: isSharedBook(book),
        entryCount: values.taggedEntries,
      })
    },
    [fns, book, field]
  )
}

export function useFetchCategorySuggestions(
  bookId: string,
  { entryType }: { entryType: "cash-in" | "cash-out" }
) {
  const [categorySuggestions, setCategorySuggestions] = useSyncedStorageState<{
    [key: string]: { suggestions: TBookEntryFields; syncedAt: Date }
  }>("categorySuggestions", {})
  const fns = useFunctions()
  return useCallback(
    async function fetchSuggestions() {
      if (
        categorySuggestions[bookId]?.suggestions?.length &&
        categorySuggestions[bookId]?.syncedAt
      ) {
        const prevDate = categorySuggestions[bookId].syncedAt
        const interval = intervalToDuration({
          start: new Date(prevDate),
          end: new Date(),
        })
        if (interval.hours !== undefined && interval.hours < 24) {
          return categorySuggestions[bookId].suggestions
        }
      }
      const { data } = await httpsCallable<
        { cashbookId: string; type: "cash-in" | "cash-out" },
        Array<string>
      >(
        fns,
        "getCategorySuggestions"
      )({
        cashbookId: bookId,
        type: entryType,
      })
      const suggestions: TBookEntryFields = data.map((cat) => ({
        uuid: bookId + cat,
        name: cat,
      }))
      setCategorySuggestions({
        ...categorySuggestions,
        [bookId]: { suggestions, syncedAt: new Date() },
      })
      return suggestions
    },
    [categorySuggestions, bookId, fns, entryType, setCategorySuggestions]
  )
}

//GENERATING PDF using backend
export type ReportOptionTypes =
  | "all"
  | "day_wise"
  | "categories"
  | "payment_mode"
  | "parties"

export type ENTRY_TYPE_PDF_PAYLOAD =
  | { name: "Cash In"; type: "cash-in" }
  | { name: "Cash Out"; type: "cash-out" }

type ENTRY_FIELDS_PAYLOAD = Array<{ uuid: string; name: string }>

export type DURATION_FILTER = {
  startDate?: Date
  endDate?: Date
  label?: string
}

export type OPTIONS_FILTER_PAYLOAD = {
  searchTerm?: string
  includesOpeningBalance?: boolean
  openingBalance?: number
}

export type REQUIRED_PDF_PAYLOAD = {
  bookId: string
  type: ReportOptionTypes
}

export type FILTERS_PDF_PAYLOAD = {
  party?: {
    parties: ENTRY_FIELDS_PAYLOAD
  }
  members?: {
    user: {
      name: string
      phoneNumber: string
      uid: string
    }
  }
  entryType?: ENTRY_TYPE_PDF_PAYLOAD
  category?: {
    categories: ENTRY_FIELDS_PAYLOAD
  }
  paymentMode?: {
    paymentModes: ENTRY_FIELDS_PAYLOAD
  }
}

export type Column_Names =
  | "date"
  | "cash-in"
  | "cash-out"
  | "balance"
  | "category"
  | "remark"
  | "payment_mode"
  | "member"
  | "party_name"
  | "name_number"
  | "applied_filters"
  | "time"

export type COLUMNS_PAYLOAD = { customFields?: string[] } & {
  [key in Column_Names]: boolean
}

export type GENERATE_PDF_PAYLOAD = REQUIRED_PDF_PAYLOAD & {
  type: ReportOptionTypes
  filters?: FILTERS_PDF_PAYLOAD
  duration?: DURATION_FILTER
  options?: OPTIONS_FILTER_PAYLOAD
  columns?: COLUMNS_PAYLOAD
}

export function useGeneratePDF(book: TBook) {
  const fns = useFunctions()
  const [status, setStatus] = useState<
    "init" | "in_progress" | "success" | "failed"
  >("init")
  const downloadPdf = useCallback(
    async (payload: GENERATE_PDF_PAYLOAD, fileName: string) => {
      setStatus("in_progress")
      try {
        const { data } = await httpsCallable<GENERATE_PDF_PAYLOAD>(
          fns,
          "generateReportFromHtml"
        )({
          ...payload,
        })
        if (typeof data !== "string") {
          throw new Error("Not able to generate PDF at the moment.")
        }
        setStatus("success")
        const blob = b64toBlob(data, "application/pdf")
        saveBlobAs(blob, fileName)
        toast.success(`Successfully Downloaded. Please check your downloads.`)
        trackEvent(TrackingEvents.GENERATE_PDF_REPORT, {
          sharedBook: isSharedBook(book),
          member: payload.filters?.members?.user ? 1 : 0,
          search: Boolean((payload.options?.searchTerm || "").trim()),
          dateFilter: payload.duration?.label || "",
          type: payload.filters?.entryType?.type,
          reportType: payload.type,
          categoriesCount: payload.filters?.category?.categories.length || 0,
          paymentModeCount:
            payload.filters?.paymentMode?.paymentModes.length || 0,
          bookId: book.id,
        })
      } catch (e) {
        const error = e as Error
        logError(error)
        setStatus("failed")
        toast.error(
          `${
            error.message || `Can't generate PDF at the moment.`
          } Please try again later!`
        )
        trackEvent(TrackingEvents.GENERATE_PDF_ERROR, {
          sharedBook: isSharedBook(book),
          member: payload.filters?.members?.user ? 1 : 0,
          search: Boolean((payload.options?.searchTerm || "").trim()),
          dateFilter: payload.duration?.label || "",
          type: payload.filters?.entryType?.type,
          reportType: payload.type,
          from: "backend",
          errorMessage: error.message,
          categoriesCount: payload.filters?.category?.categories.length || 0,
          paymentModeCount:
            payload.filters?.paymentMode?.paymentModes.length || 0,
          bookId: book.id,
        })
      }
    },
    [fns, book]
  )
  return {
    status,
    downloadPdf,
  }
}

export function useDuplicateBook(bookId: string) {
  const fns = useFunctions()
  return useCallback(
    async (values: { name: string; duplicate: Array<string> }) => {
      const {
        data: { newBookId, status },
      } = await httpsCallable<
        { bookId: string; name: string; duplicate: Array<string> },
        { newBookId: string; status: string }
      >(
        fns,
        "duplicateCashBook"
      )({
        bookId,
        ...values,
      })
      if (status !== "success") {
        throw new Error("Something went wrong. Try again later.")
      }
      await sleep(1000)
      return {
        newBookId,
      }
    },
    [bookId, fns]
  )
}

export function usePartyOrContact() {
  const { data: userInfo } = useUser()
  const isUserIndian = isVisitorIndian()
  let partyOrContact = "Party"
  let partiesOrContacts = "Parties"
  if (
    (userInfo?.phoneNumber && !isPhoneNumberIndian(userInfo.phoneNumber)) ||
    (!userInfo?.phoneNumber && !isUserIndian)
  ) {
    partyOrContact = "Contact"
    partiesOrContacts = "Contacts"
  }
  return { partyOrContact, partiesOrContacts }
}

//Business layer for books
export function useBooksForBusinessId(businessId: string) {
  const booksCollection = useBooksCollection()
  const { data: user } = useUser()
  const booksQuery = query(
    booksCollection,
    where("businessId", "==", businessId),
    where("sharedWith", "array-contains", user?.uid),
    orderBy("createdAt", "desc")
  )
  const { data: books } = useFirestoreCollectionData(booksQuery, {
    idField: "id",
  })

  const getBooksForTeamMember = useCallback(
    (teamMemberId: string) => {
      const booksForMember = books.filter((book) =>
        book.sharedWith.includes(teamMemberId)
      )
      return booksForMember as Array<TBook>
    },
    [books]
  )

  const getBooksForInvitedMember = useCallback(
    (sharedBooks: Array<string>) => {
      const booksForInvitedMember = books.filter((book) =>
        sharedBooks.includes(book.id)
      )
      return booksForInvitedMember as Array<TBook>
    },
    [books]
  )

  const getBookNamesById = useCallback(() => {
    const booksById: { [key: string]: string } = {}
    books.forEach((book) => {
      if (!booksById[book.id]) {
        booksById[book.id] = book.name
      }
    })
    return booksById
  }, [books])

  return {
    books,

    getBookNamesById,
    getBooksForTeamMember,
    getBooksForInvitedMember,
  }
}

export type TBookFilterParams = {
  q?: string
  sortBy?: SortBookBy
}
const InitialParams: TBookFilterParams = {
  q: "",
  sortBy: "byLastUpdated",
}

export function useBusinessBooksSearch(
  businessId: string,
  initialSearchParamsProp: TBookFilterParams = InitialParams
) {
  const { books: baseBooks } = useBooksForBusinessId(businessId)
  const initialSearchParams = useMemo(() => {
    if (!initialSearchParamsProp) return InitialParams
    return {
      ...InitialParams,
      ...initialSearchParamsProp,
      q: initialSearchParamsProp.q || InitialParams.q,
    }
  }, [initialSearchParamsProp])
  const {
    values: params,
    handleChange: handleParamsChange,
    setFieldValue,
  } = useFormik<TBookFilterParams>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })
  const hasAppliedFilters = useMemo(() => {
    const { q, sortBy } = params
    return Boolean(q?.trim() || sortBy)
  }, [params])
  const books = useMemo(() => {
    if (!params.q?.trim()) return baseBooks
    const q = params.q
    return baseBooks.filter((b) =>
      b.name.toLowerCase().includes(q.trim().toLowerCase())
    )
  }, [baseBooks, params])
  return {
    allBooks: baseBooks,
    books,
    params,
    hasAppliedFilters,
    handleParamsChange,
    setParamValue: setFieldValue,
  }
}

export type SortBookBy =
  | "byBookName"
  | "byBalanceAsc"
  | "byBalanceDesc"
  | "byLastCreated"
  | "byLastUpdated"
function getSortedCashbookList(
  books: TBook[],
  userId: string,
  sortBy?: SortBookBy
) {
  let cashbooks: TBook[] = []
  switch (sortBy) {
    case "byBookName":
      cashbooks = [...books]
      cashbooks.sort((cashbookA, cashbookB) => {
        return cashbookA.name.localeCompare(cashbookB.name)
      })
      return cashbooks
    case "byBalanceDesc": {
      cashbooks = [...books]
      cashbooks.sort((cashbookA, cashbookB) => {
        if (
          (cashbookA.preferences?.hideBalancesAndReports ||
            cashbookA.preferences?.hideEntriesByOthers) &&
          !checkIfMemberCan(
            cashbookA,
            userId,
            BOOK_PERMISSIONS.VIEW_NET_BALANCE
          )
        ) {
          return 1
        } else if (
          (cashbookB.preferences?.hideBalancesAndReports ||
            cashbookB.preferences?.hideEntriesByOthers) &&
          !checkIfMemberCan(
            cashbookB,
            userId,
            BOOK_PERMISSIONS.VIEW_NET_BALANCE
          )
        ) {
          return -1
        }
        const balanceCashbookA =
          (cashbookA.totalCashIn || 0) - (cashbookA.totalCashOut || 0)
        const balanceCashbookB =
          (cashbookB.totalCashIn || 0) - (cashbookB.totalCashOut || 0)
        return balanceCashbookB - balanceCashbookA
      })
      return cashbooks
    }
    case "byBalanceAsc": {
      const cashbooks = [...books]
      cashbooks.sort((cashbookA, cashbookB) => {
        if (
          (cashbookA.preferences?.hideBalancesAndReports ||
            cashbookA.preferences?.hideEntriesByOthers) &&
          !checkIfMemberCan(
            cashbookA,
            userId,
            BOOK_PERMISSIONS.VIEW_NET_BALANCE
          )
        ) {
          return 1
        } else if (
          (cashbookB.preferences?.hideBalancesAndReports ||
            cashbookB.preferences?.hideEntriesByOthers) &&
          !checkIfMemberCan(
            cashbookB,
            userId,
            BOOK_PERMISSIONS.VIEW_NET_BALANCE
          )
        ) {
          return -1
        }

        const balanceCashbookA =
          (cashbookA.totalCashIn || 0) - (cashbookA.totalCashOut || 0)
        const balanceCashbookB =
          (cashbookB.totalCashIn || 0) - (cashbookB.totalCashOut || 0)
        return balanceCashbookA - balanceCashbookB
      })
      return cashbooks
    }
    case "byLastUpdated":
      cashbooks = [...books]
      cashbooks.sort((cashbookA, cashbookB) => {
        const cashbookAUpdated = timeStampToDate(
          cashbookA.updatedAt ||
            cashbookA.createdAt ||
            new Timestamp(new Date().getSeconds(), new Date().getSeconds())
        )
        const cashbookBUpdated = timeStampToDate(
          cashbookB.updatedAt ||
            cashbookB.createdAt ||
            new Timestamp(new Date().getSeconds(), new Date().getSeconds())
        )
        return cashbookBUpdated.getTime() - cashbookAUpdated.getTime()
      })
      return cashbooks
    case "byLastCreated":
      cashbooks = [...books]
      cashbooks.sort((cashbookA, cashbookB) => {
        const cashbookAUpdated = timeStampToDate(
          cashbookA.createdAt ||
            cashbookA.updatedAt ||
            new Timestamp(new Date().getSeconds(), new Date().getSeconds())
        )
        const cashbookBUpdated = timeStampToDate(
          cashbookB.createdAt ||
            cashbookB.updatedAt ||
            new Timestamp(new Date().getSeconds(), new Date().getSeconds())
        )
        return cashbookBUpdated.getTime() - cashbookAUpdated.getTime()
      })
      return cashbooks
    default:
      return cashbooks
  }
}

export function useBusinessBooksSearchForMember(
  businessId: string,
  teamMemberId: string,
  initialSearchParamsProp: TBookFilterParams = InitialParams
) {
  const { getBooksForTeamMember } = useBooksForBusinessId(businessId)
  const baseBooks = getBooksForTeamMember(teamMemberId)
  const initialSearchParams = useMemo(() => {
    if (!initialSearchParamsProp) return InitialParams
    return {
      ...InitialParams,
      ...initialSearchParamsProp,
      q: initialSearchParamsProp.q || InitialParams.q,
    }
  }, [initialSearchParamsProp])
  const {
    values: params,
    handleChange: handleParamsChange,
    setFieldValue,
  } = useFormik<TBookFilterParams>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })
  const hasAppliedFilters = useMemo(() => {
    const { q, sortBy } = params
    return Boolean(q?.trim() || sortBy)
  }, [params])
  const books: TBook[] = useMemo(() => {
    if (!hasAppliedFilters) return [...baseBooks]
    let booksByAppliedFilters = [...baseBooks]
    if (params.sortBy) {
      booksByAppliedFilters = [
        ...getSortedCashbookList(
          booksByAppliedFilters,
          teamMemberId,
          params.sortBy
        ),
      ]
    }
    return filterBooks(booksByAppliedFilters, params)
  }, [baseBooks, params, hasAppliedFilters, teamMemberId])
  return {
    allBooks: baseBooks,
    books,
    params,
    hasAppliedFilters,
    handleParamsChange,
    setParamValue: setFieldValue,
  }
}

export function filterBooks(
  books: TBook[],
  params: TBookFilterParams
): Array<TBook> {
  const { q } = params
  const filtered = books.filter((book) => {
    if (
      q?.trim() &&
      !(book.name || "").toLowerCase().includes(q.toLowerCase())
    ) {
      return false
    }
    return true
  })
  return filtered
}

export function useBooksRecommendations() {
  const fns = useFunctions()
  return useCallback(async () => {
    try {
      const {
        data: { group },
      } = await httpsCallable<
        { lang: "en" },
        { group: string[]; private: string[] }
      >(
        fns,
        "getBookNameSuggestions"
      )({
        lang: "en",
      })
      if (!group) {
        throw new Error("Something went wrong. Try again later.")
      }
      return group
    } catch (e) {
      const err = e as Error
      throw new Error(err.message)
    }
  }, [fns])
}


export const getAllMandatoryFields = (
  preferences?: TBookPreferences,
  customFields?: TBookCustomFields
) => {
  const mandatoryFields: string[] = []
  if (!preferences?.categoriesDisabled && preferences?.categoriesRequired) {
    mandatoryFields.push("category")
  }
  if (!preferences?.paymentModesDisabled && preferences?.paymentModesRequired) {
    mandatoryFields.push("paymentMode")
  }
  if (customFields && Object.keys(customFields).length > 0) {
    Object.keys(customFields).forEach((customFieldId) => {
      if (customFieldId && customFields[customFieldId].required) {
        mandatoryFields.push(customFieldId)
      }
    })
  }
  return mandatoryFields
}

export function findMatchingMandatoryCustomFieldIds(
  sourceBook: TBook,
  destinationBook: TBook
) {
  const sourceFields = sourceBook.customFields || {}
  const destinationFields = destinationBook.customFields || {}
  const matchingIds: string[] = []

  // Iterate over the custom fields in the source book
  for (const key in sourceFields) {
    const sourceField = sourceFields[key]

    // Check if there is a field in the destination book with the same name
    for (const destKey in destinationFields) {
      const destinationField = destinationFields[destKey]
      if (
        sourceField.name &&
        destinationField.name &&
        sourceField.name === destinationField.name &&
        destinationField.required
      ) {
        if (key) {
          // Ensure there is an id to push
          matchingIds.push(key) // Store the matching id
        }
      }
    }
  }

  // Return the array of matching ids
  return matchingIds
}

function useSyncedStorageState<T>(
  key: string,
  defaultValue: T
): [state: T, setState: (state: T) => void] {
  const [state, setState] = useState<T>(() => {
    try {
      const savedPreferences = localStorage.getItem(key)
      if (!savedPreferences) return defaultValue
      return JSON.parse(savedPreferences)
    } catch (e) {
      return defaultValue
    }
  })
  const updateState = useCallback(
    (state: T) => {
      setState(state)
      try {
        localStorage.setItem(key, JSON.stringify(state))
      } catch (e) {
        logError(
          "Your preferences cannot be saved because of some missing permissions."
        )
      }
    },
    [key]
  )
  return [state, updateState]
}
