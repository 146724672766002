import { formatDate, isBeforeDate, isSameDay } from "@cashbook/util-dates"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Button,
  DataLoadingFallback,
  DotsVerticalIcon,
  ExcelFileIcon,
  getButtonClassName,
  InformationCircleIcon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemHeader,
  MenuLink,
  MenuList,
  MinusIcon,
  PageMeta,
  PDFIcon,
  PlusIcon,
  Time,
  TransactionDate,
  Box,
  Text,
  Stack,
  Inline,
  CloudOffIcon,
} from "@cashbook/web-components"
import React, { useMemo } from "react"
import { Link, NavLink, useParams, useSearchParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../../ErrorBoundary"
import {
  useTransactionsSearch,
  isTransactionUpdated,
  resolveAppliedDateInterval,
  TBook,
  BOOK_PERMISSIONS,
  useBook,
} from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import { Amount } from "../../support/Intl"
import {
  BillImage,
  ExportTransactionsInModal,
  getLabelForTransactionType,
  LogTransactionInDialog,
} from "../../Transactions"
import Page from "./Page"
import { useBusiness } from "@cashbook/data-store/businesses"

export default function SmBookTransactionsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <BookTransactions key={bookId} bookId={bookId} businessId={businessId} />
  )
}

function BookTransactions({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book } = useBook(bookId)
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  if (!book.name) return null
  return (
    <>
      <PageMeta>
        <title>{book.name}'s Transactions</title>
      </PageMeta>
      <ErrorBoundary>
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading Entries..." />}
          traceId="loading_transactions"
        >
          <Transactions book={book} businessId={businessId} />
        </SuspenseWithPerf>
      </ErrorBoundary>
    </>
  )
}

function Transactions({
  book,
  businessId,
}: {
  book: TBook
  businessId: string
}) {
  const [searchParams] = useSearchParams()
  const q = searchParams.get("q") || undefined
  const { authTeamMemberDetails } = useBusiness(businessId)
  const {
    params,
    transactions,
    totalTransactions,
    handleParamChange,
    // setParamValue,
    resetParams,
    hasAppliedFilters,
    openingBalance,
    loadMoreTransactions,
    hasMoreTransactions,
    totalCashIn,
    totalCashOut,
    closingBalance,
    totalFilteredTransactions,
    closingBalancePerTransaction,
    resolveAttributesFromBook,
    resolveTransactionAttributesFromInvolvedUsers,
  } = useTransactionsSearch(book, { q }, authTeamMemberDetails.role.id)
  const {
    involvedUsers,
    authMemberDetails,
    isShared,
    checkIfAuthenticatedMemberCan,
  } = useBook(book.id)
  const { user } = useProfile()

  const { afterDate, beforeDate } = params

  const resolvedDateIntervals = useMemo(
    () => resolveAppliedDateInterval([afterDate, beforeDate]),
    [afterDate, beforeDate]
  )
  return (
    <Page
      title={book.name}
      backTo={`/m/businesses/${businessId}/cashbooks`}
      actions={
        <Inline gap="8" alignItems="center" whiteSpace="preserve">
          <ExportTransactionsInModal
            searchParams={params}
            book={book}
            involvedUsers={involvedUsers}
          >
            {({ onExport }) => (
              <Menu>
                <MenuButton inline>
                  <DotsVerticalIcon />
                </MenuButton>
                <MenuList align="bottom-right">
                  <MenuLink
                    to={`/m/businesses/${businessId}/cashbooks/${book.id}/settings`}
                  >
                    Book Details
                  </MenuLink>
                  {checkIfAuthenticatedMemberCan(
                    BOOK_PERMISSIONS.DOWNLOAD_REPORTS
                  ) ? (
                    <>
                      <MenuItemHeader className="border-t mt-2">
                        Reports
                      </MenuItemHeader>
                      <MenuItem
                        action="excel-export"
                        className="whitespace-pre"
                        onClick={() => onExport("excel")}
                      >
                        <ExcelFileIcon /> Excel Report
                      </MenuItem>
                      <MenuItem
                        action="pdf-export"
                        className="whitespace-pre"
                        onClick={() => onExport("pdf")}
                      >
                        <PDFIcon /> PDF Report
                      </MenuItem>
                    </>
                  ) : null}
                </MenuList>
              </Menu>
            )}
          </ExportTransactionsInModal>
        </Inline>
      }
      footer={
        checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_CASH_IN_OUT) ? (
          <Box width="full">
            <CashInCashOutLink book={book} businessId={businessId} />
          </Box>
        ) : null
      }
    >
      {totalTransactions === 0 ? (
        <>
          <Stack gap="16" paddingX="4" paddingY="16" alignItems="center">
            <Stack gap="8" alignItems="center">
              <Box textAlign="center">
                <Text fontSize="lg" color="gray500">
                  No entries in this book.
                </Text>
              </Box>
            </Stack>
            <Stack gap="4" color="gray500">
              <Text fontSize="sm">
                Use{" "}
                <Text as="b" color="green900">
                  <PlusIcon size="4" /> Cash In
                </Text>{" "}
                for entries where you receive (credit) an amount
              </Text>
              <Text fontSize="sm">
                Use{" "}
                <Text as="b" color="red900">
                  <MinusIcon size="4" /> Cash Out
                </Text>{" "}
                for entries where you send (debit) an amount.
              </Text>
              <Text fontSize="sm">
                Note that this doesn't move money between bank accounts.
              </Text>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack gap="4">
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack gap="2">
              <Input
                type="search"
                name="q"
                placeholder="Search by Remark"
                value={params.q}
                onChange={handleParamChange}
                className="w-full"
                onFocus={() => {
                  trackEvent(TrackingEvents.SET_SEARCH_FOCUS)
                }}
              />
              <Box display="none">
                <Button type="reset" onClick={() => resetParams()}>
                  Reset
                </Button>
              </Box>
            </Stack>
          </form>
          {transactions.length &&
          checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.VIEW_NET_BALANCE) ? (
            <Box paddingX="4">
              <Box
                borderWidth="1"
                bgColor="white"
                rounded="md"
                overflow="hidden"
              >
                <Overview
                  totalCashIn={totalCashIn}
                  totalCashOut={totalCashOut}
                  closingBalance={closingBalance}
                  openingBalance={params.afterDate ? openingBalance : undefined}
                />
              </Box>
            </Box>
          ) : null}
          {transactions.length ? (
            <Stack gap="8" className="pb-36">
              <Stack gap="2">
                <Box textAlign="right" paddingX="4">
                  <Text fontSize="xs" color="gray500" fontWeight="medium">
                    Showing {transactions.length} of {totalFilteredTransactions}{" "}
                    Entries
                  </Text>
                </Box>
                <Stack as="ol" reversed>
                  {transactions.map((t) => {
                    resolveAttributesFromBook(t)
                    resolveTransactionAttributesFromInvolvedUsers(
                      involvedUsers,
                      t
                    )
                    return (
                      <Box
                        as={NavLink}
                        key={t.id}
                        to={t.id}
                        display="block"
                        bgColor="white"
                        paddingX="4"
                        paddingY="3"
                        borderBottomWidth="1"
                        onClick={() => {
                          trackEvent(TrackingEvents.ENTRY_CLICKED, {
                            sharedBook: isShared,
                            billAttached: Boolean(t.imageUrl),
                            role: authMemberDetails.role.id,
                            isEdited: isTransactionUpdated(t),
                            synced: true,
                            entryBySelf: Boolean(
                              t.createdBy === authMemberDetails.id
                            ),
                          })
                        }}
                      >
                        <Inline justifyContent="between" gap="4">
                          <Inline flex="1" gap="4">
                            {!t.createdAt ? (
                              <CloudOffIcon
                                size="6"
                                title="Entry is NOT backed up"
                              />
                            ) : null}
                            <Stack flex="1" gap="1">
                              {t.party ? (
                                <Inline gap="1">
                                  <Text
                                    fontWeight="medium"
                                    className="break-words"
                                  >
                                    {t.party.name}
                                  </Text>
                                  {t.party.phoneNumber ? (
                                    <Text color="gray500">
                                      ({t.party.phoneNumber})
                                    </Text>
                                  ) : null}
                                </Inline>
                              ) : null}
                              <Inline gap="2">
                                {involvedUsers.length > 1 ? (
                                  <Text fontSize="sm">
                                    by{" "}
                                    {t.createdBy === user.uid
                                      ? "You"
                                      : involvedUsers.find(
                                          (u) => u.id === t.createdBy
                                        )?.name}
                                  </Text>
                                ) : null}
                              </Inline>
                              {t.thumbUrl && t.imageUrl ? (
                                <Box>
                                  <BillImage
                                    thumbUrl={t.thumbUrl}
                                    imageUrl={t.imageUrl}
                                    width="6"
                                    height="8"
                                  />
                                </Box>
                              ) : null}
                              {t.category || t.paymentMode ? (
                                <Inline gap="2">
                                  {t.category ? (
                                    <Box
                                      paddingX="3"
                                      paddingY="1"
                                      rounded="md"
                                      className="bg-category bg-opacity-10 text-category"
                                      title="Category"
                                    >
                                      <Text
                                        fontWeight="semibold"
                                        fontSize="sm"
                                        as="span"
                                      >
                                        {t.category.name}
                                      </Text>
                                    </Box>
                                  ) : null}
                                  {t.paymentMode ? (
                                    <Box
                                      className="bg-paymentMode bg-opacity-10 text-paymentMode"
                                      title="Payment Mode"
                                      paddingX="3"
                                      paddingY="1"
                                      rounded="md"
                                    >
                                      <Text
                                        fontWeight="semibold"
                                        fontSize="sm"
                                        as="span"
                                      >
                                        {t.paymentMode.name}
                                      </Text>
                                    </Box>
                                  ) : null}
                                </Inline>
                              ) : null}
                              {t.remark ? (
                                <Text
                                  color="gray500"
                                  fontSize="sm"
                                  className="break-words"
                                >
                                  {t.remark}
                                </Text>
                              ) : null}
                              <Inline gap="2">
                                <Text
                                  color="gray500"
                                  fontSize="xs"
                                  whiteSpace="preserve"
                                >
                                  <TransactionDate timeStamp={t.date} />
                                  {" at "}
                                  <Time timeStamp={t.date} format="hh:mm a" />
                                </Text>
                                {isTransactionUpdated(t) &&
                                t.updatedAt &&
                                t.updatedBy ? (
                                  <Text fontSize="xs" color="gray500">
                                    (Edited)
                                  </Text>
                                ) : null}
                              </Inline>
                            </Stack>
                          </Inline>
                          <Stack alignItems="end" gap="1">
                            <Amount
                              amount={t.amount}
                              fontWeight="medium"
                              color={
                                t.type === "cash-in" ? "green900" : "red900"
                              }
                            />
                            {checkIfAuthenticatedMemberCan(
                              BOOK_PERMISSIONS.VIEW_NET_BALANCE
                            ) && (
                              <Text
                                fontSize="xs"
                                color="gray500"
                                fontWeight="normal"
                              >
                                Balance:{" "}
                                <Amount
                                  amount={closingBalancePerTransaction[t.id]}
                                />
                              </Text>
                            )}
                          </Stack>
                        </Inline>
                      </Box>
                    )
                  })}
                </Stack>
              </Stack>
              {hasMoreTransactions ? (
                <Button
                  onClick={loadMoreTransactions}
                  level="primary"
                  size="sm"
                >
                  Show More Transactions
                </Button>
              ) : null}
            </Stack>
          ) : hasAppliedFilters ? (
            <Stack gap="4" textAlign="center" paddingY="8">
              <Text fontWeight="medium" color="gray500">
                No{params.type ? ` ${params.type}` : ``} entries added
                {resolvedDateIntervals.allTimeApplied
                  ? ""
                  : resolvedDateIntervals.customDateApplied
                  ? ` between ${
                      params.afterDate
                        ? formatDate(params.afterDate, "dd MMM")
                        : "Start"
                    } - ${
                      params.beforeDate
                        ? formatDate(params.beforeDate, "dd MMM")
                        : "Now"
                    }`
                  : ` ${resolvedDateIntervals.appliedDateIntervalLabel}`.toLowerCase()}
                {params.entryBy ? ` by ${params.entryBy.name}` : ``}!
              </Text>
              {params.afterDate &&
              isBeforeDate(params.afterDate, new Date()) &&
              !isSameDay(params.afterDate, new Date()) ? (
                <Text>
                  <InformationCircleIcon /> You can add backdated entries using{" "}
                  <b>{getLabelForTransactionType("cash-in")}</b> or{" "}
                  <b>{getLabelForTransactionType("cash-out")}</b>
                </Text>
              ) : null}
            </Stack>
          ) : (
            <Box textAlign="center" paddingY="16">
              <Text>No entries in this book</Text>
            </Box>
          )}
        </Stack>
      )}
    </Page>
  )
}

function CashInCashOutLink({
  book,
  businessId,
  ...props
}: { book: TBook; businessId: string } & Omit<
  React.ComponentProps<typeof LogTransactionInDialog>,
  "children" | "type"
>) {
  const { checkIfAuthenticatedMemberCan } = useBook(book.id)
  if (!checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_CASH_IN_OUT))
    return null
  return (
    <Inline gap="4">
      <LogTransactionInDialog book={book} {...props}>
        {({ add }) => (
          <>
            <Box width="1/2">
              <Link
                to={`/m/businesses/${businessId}/cashbooks/${book.id}/transactions/create?type=cash-in`}
                onClick={() => {
                  trackEvent(TrackingEvents.CASH_IN_CLICKED)
                }}
                className={getButtonClassName({
                  status: "success",
                  level: "primary",
                  fullWidth: true,
                })}
              >
                <PlusIcon size="6" /> {getLabelForTransactionType("cash-in")}
              </Link>
            </Box>
            <Box width="1/2">
              <Link
                to={`/m/businesses/${businessId}/cashbooks/${book.id}/transactions/create?type=cash-out`}
                onClick={() => {
                  add("cash-out")
                  trackEvent(TrackingEvents.CASH_OUT_CLICKED)
                }}
                className={getButtonClassName({
                  status: "error",
                  level: "primary",
                  fullWidth: true,
                })}
              >
                <MinusIcon size="6" /> {getLabelForTransactionType("cash-out")}
              </Link>
            </Box>
          </>
        )}
      </LogTransactionInDialog>
    </Inline>
  )
}

function Overview({
  totalCashIn,
  totalCashOut,
  closingBalance,
  openingBalance,
}: {
  totalCashIn: number
  totalCashOut: number
  closingBalance: number
  openingBalance?: number
}) {
  return (
    <Stack bgColor="white">
      <Box borderBottomWidth="1" padding="2" fontWeight="medium">
        <OverviewItem label="Net Balance" amount={closingBalance} />
      </Box>
      <Stack padding="2" gap="1" fontSize="sm">
        {openingBalance !== undefined ? (
          <OverviewItem label="Opening Balance" amount={openingBalance} />
        ) : null}
        <OverviewItem label="Total In (+)" amount={totalCashIn} />
        <OverviewItem label="Total Out (-)" amount={totalCashOut} />
      </Stack>
    </Stack>
  )
}

function OverviewItem({
  label,
  amount,
}: {
  label: React.ReactNode
  amount: number
}) {
  return (
    <Inline justifyContent="between">
      <Text>{label}</Text>
      <Amount amount={amount} />
    </Inline>
  )
}
