import { arePhoneNumbersSame, queryToSearch } from "@cashbook/util-general"
import {
  setAnanlyticsIdentityProperties,
  trackEvent,
  TrackingEvents,
} from "@cashbook/util-tracking"
import {
  Alert,
  Button,
  CheckCircleSolidIcon,
  ChevronDownIcon,
  FormField,
  LogoIcon,
  PageMeta,
  SpinnerIcon,
  Box,
  Inline,
  Stack,
  Text,
  Heading,
  getButtonClassName,
  BusinessBuildingIcon,
} from "@cashbook/web-components"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { SuspenseWithPerf, useUser } from "reactfire"
import * as Validator from "yup"
import config from "../config"
import { TUser, useRegisterUserWithBusiness } from "@cashbook/data-store/users"
import sharedBookIllustrationUrl from "./../SharedBookInvitations/shared_cashbook_illustartion.png"
import {
  TBusinessInvitation,
  useShareBusinessInvitation,
  useAcceptBusinessInvitation,
} from "@cashbook/data-store/businesses"
import {
  setSyncStoredItem,
  useSyncedStorageState,
} from "@cashbook/data-store/storage"
import { useLogout } from "../Auth"

export default function AcceptSharedBookInvitationPage() {
  const logout = useLogout()
  const { data: authUser } = useUser()
  const [searchParams] = useSearchParams()
  const invitationToken = searchParams.get("token")
  const isAfterSignup = Boolean(searchParams.get("after-signup"))
  const { invitation, status, error } =
    useShareBusinessInvitation(invitationToken)
  const [user, setUser] = useState<TUser>()
  const createProfile = useRegisterUserWithBusiness()
  // Track if the user attempted to signup using a different phoneNumber then the request one
  useEffect(() => {
    if (isAfterSignup && invitation && user && user.displayName) {
      const authUserPhoneNumber = user.phoneNumber
      const authUserId = user.uid
      if (!arePhoneNumbersSame(authUserPhoneNumber, invitation.guestPhone)) {
        trackEvent(TrackingEvents.INVITE_DIFFERENT_PHONE_REGISTER_ATTEMPT)
      }
      // set the identity property for signup via link
      setAnanlyticsIdentityProperties(authUserId, {
        viaInviteLink: true,
      })
    }
  }, [isAfterSignup, user, invitation])

  return (
    <>
      <PageMeta>
        <title>{invitation?.hostName || "Friend"}'s Invitation</title>
      </PageMeta>
      <Stack paddingY={{ md: "8" }} justifyContent="center" alignItems="center">
        <Stack maxWidth="4xl" bgColor="white" rounded="lg" gap="4">
          <Inline
            alignItems="center"
            justifyContent="center"
            gap="2"
            padding="4"
          >
            <LogoIcon size="12" />
            <Heading as="h2" fontSize="2xl" fontWeight="medium">
              {config.appTitle} Business Invite
            </Heading>
          </Inline>
          <Box height="px" bgColor="gray100"></Box>
          <Inline
            collapseBelow="sm"
            alignItems="center"
            justifyContent="center"
            padding="4"
          >
            <Stack
              width={{ sm: "1/2" }}
              alignItems="center"
              justifyContent="center"
            >
              <Box maxWidth={{ xs: "xs", sm: "full" }}>
                <Box
                  as="img"
                  src={sharedBookIllustrationUrl}
                  alt="Shared Book Illustration"
                  size="full"
                />
              </Box>
            </Stack>
            <Box flex="1" width="full" minWidth="0">
              {!invitationToken ? (
                <Stack gap="4">
                  <Heading as="h1" fontSize="3xl">
                    Missing Invitation
                  </Heading>
                  <Text>
                    You are accessing a business invitation page but there is no
                    invitation with the shared link.
                  </Text>
                  <Text>
                    Please re-check the invitation link and try again.
                  </Text>
                  <Link
                    to="/"
                    className={getButtonClassName({ level: "primary" })}
                  >
                    Ok, Got It. <ChevronDownIcon rotate="270" />
                  </Link>
                </Stack>
              ) : status === "failed" ? (
                <Stack gap="8">
                  <Stack gap="4">
                    <Heading as="h1" fontSize="xl">
                      Oops!
                    </Heading>
                    <Text color="gray500">
                      There seems to be an issue with this invitation.
                    </Text>
                  </Stack>
                  <Alert status="error">{error?.message}</Alert>
                  <Text color="gray500">
                    If you need more assistance, please contact our support.
                  </Text>
                  <Link
                    to="/"
                    className={getButtonClassName({ level: "primary" })}
                  >
                    Ok, Got It. <ChevronDownIcon rotate="270" />
                  </Link>
                </Stack>
              ) : status === "in_progress" ? (
                <Box textAlign="center">
                  <SpinnerIcon /> Loading...
                </Box>
              ) : status === "success" && invitation ? (
                <Box>
                  {authUser?.email !== invitation?.guestEmail ||
                  authUser?.phoneNumber !== invitation?.guestPhone ? (
                    <Stack gap="8">
                      <Alert status="warning">
                        <Stack gap="1">
                          <Heading as="h2" fontSize="s4">
                            Can Not Join
                          </Heading>
                          <Text>
                            Sorry, the invite link you opened is shared with
                            different {config.appTitle} Account.
                          </Text>
                        </Stack>
                      </Alert>
                      <Stack gap="4">
                        <Text fontSize="b1">
                          {invitation.hostName} wants to add you to the{" "}
                          {config.appTitle} using “
                          {invitation?.guestEmail || invitation?.guestPhone}"
                        </Text>
                        <Text fontSize="b3">
                          Please ask {invitation.hostName} to invite you to{" "}
                          {authUser?.email || authUser?.phoneNumber} in case,
                          above {config.appTitle} account is not correct.
                        </Text>
                      </Stack>
                      <Box>
                        <Link
                          to={`/`}
                          className={getButtonClassName({
                            level: "primary",
                            size: "lg",
                          })}
                        >
                          Ok. Got It.
                        </Link>
                      </Box>
                    </Stack>
                  ) : !user?.displayName ? (
                    <Box>
                      <Formik
                        initialValues={{ displayName: "" }}
                        validationSchema={Validator.object().shape({
                          displayName: Validator.string()
                            .required(
                              "Your name is required to join the shared book."
                            )
                            .max(
                              100,
                              "Name should be lesser than 191 characters"
                            ),
                        })}
                        onSubmit={async (values) => {
                          const { user } = await createProfile({
                            displayName: values.displayName,
                            dontCreateDefaultBusiness: true,
                          })
                          if (user.uid) {
                            setUser(user)
                          }
                          setSyncStoredItem("isNewUser", true)
                          trackEvent(TrackingEvents.NAME_SUBMITTED)
                        }}
                      >
                        {({ isSubmitting, status }) => (
                          <Form noValidate>
                            <Stack gap="4">
                              {status ? (
                                <Alert status="error">{status}</Alert>
                              ) : null}
                              <FormField
                                type="text"
                                name="displayName"
                                label="Please provide your name"
                                placeholder="Your Name Here"
                                required
                              />
                              <Box>
                                <Button type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? <SpinnerIcon /> : null}
                                  Accept Invitation{" "}
                                  {!isSubmitting && (
                                    <ChevronDownIcon rotate="270" />
                                  )}
                                </Button>
                              </Box>
                              <Box>
                                <Text fontSize="b1">
                                  You will join the{" "}
                                  {invitation.businessId
                                    ? " business"
                                    : " book"}{" "}
                                  shared by &ldquo;
                                  {invitation.hostName}&rdquo;
                                </Text>
                              </Box>
                            </Stack>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  ) : user.phoneNumber !== invitation.guestPhone ? (
                    <Stack gap="4">
                      <Alert status="warning">
                        <Stack gap="1">
                          <Heading as="h2">Can Not Join</Heading>
                          <Text>
                            Invited mobile number ({invitation.guestPhone}) is
                            different than logged in number ({user.phoneNumber}
                            ).
                          </Text>
                        </Stack>
                      </Alert>
                      <Text fontSize="md" fontWeight="medium">
                        {invitation.hostName} wants to add you to the shared
                        cashbook using your different number{" "}
                        {invitation.guestPhone}
                      </Text>
                      <Text>
                        Please ask {invitation.hostName} to invite you to this
                        number ({user.phoneNumber}).
                      </Text>
                      <Box>
                        <Link
                          to={`/`}
                          className={getButtonClassName({ level: "primary" })}
                        >
                          Ok. Got It.
                        </Link>
                      </Box>
                      <Text fontSize="sm">
                        Or you can{" "}
                        <Text
                          as="button"
                          color="blue900"
                          fontWeight="medium"
                          onClick={() => {
                            logout("fromThisDevice")
                              .then(() => {
                                window.location = (`/login` +
                                  queryToSearch({
                                    next: `/accept-invite?token=${invitation.inviteId}&after-signup=1`,
                                    phoneNumber: invitation.guestPhone,
                                  })) as unknown as Location
                              })
                              .catch((e) => {
                                window.alert(
                                  e?.message ||
                                    "Unable to log you out. Please try again"
                                )
                              })
                          }}
                        >
                          signup with {invitation.guestPhone}
                        </Text>{" "}
                        to access this book.
                      </Text>
                    </Stack>
                  ) : (
                    <Box>
                      <SuspenseWithPerf
                        fallback={
                          <Box textAlign="center">
                            <SpinnerIcon /> Loading invitation...
                          </Box>
                        }
                        traceId="update_profile_and_accept_invitation"
                      >
                        <AcceptBusinessInvitation
                          userId={user?.uid || authUser.uid}
                          invitation={invitation}
                        />
                      </SuspenseWithPerf>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Box>
          </Inline>
        </Stack>
      </Stack>
    </>
  )
}

type UserTransitionedStorage = {
  [key: string]: boolean
}
function AcceptBusinessInvitation({
  userId,
  invitation,
}: {
  userId: string
  invitation: TBusinessInvitation
}) {
  const { invitedTo, status, error } = useAcceptBusinessInvitation(
    invitation.inviteId
  )
  const [isUserTransitioned, setIsUserTransitioned] =
    useSyncedStorageState<UserTransitionedStorage>("userTransitioned", {})
  if (error) {
    return (
      <Stack gap="4">
        <Text>Oops! Looks like something went wrong.</Text>
        <Alert status="error">{error.message}</Alert>
        <hr />
        <Link to="/" className={getButtonClassName({ level: "primary" })}>
          Visit Dashboard <ChevronDownIcon rotate="270" />
        </Link>
      </Stack>
    )
  }
  if (status === "in_progress") {
    return (
      <Box>
        <Box textAlign="center">
          <SpinnerIcon /> Setting up business...
        </Box>
      </Box>
    )
  }
  if (status === "success") {
    return (
      <Stack gap="4">
        <Text fontSize="2xl" color="green900" fontWeight="medium">
          <CheckCircleSolidIcon /> Success
        </Text>
        <Text>
          You are now a member of &ldquo;{invitation.businessName}&rdquo;
          business.
        </Text>
        <hr />
        <Link
          to={
            invitedTo.bookId
              ? `/new-business-transition/${invitedTo.businessId}?bookId=${invitedTo.bookId}`
              : `/new-business-transition/${invitedTo.businessId}`
          }
          onClick={() =>
            setIsUserTransitioned({ ...isUserTransitioned, [userId]: true })
          }
          className={getButtonClassName({ level: "primary" })}
        >
          <BusinessBuildingIcon /> Visit Business
        </Link>
      </Stack>
    )
  }
  return (
    <Box>
      <SpinnerIcon /> Loading...
    </Box>
  )
}
