import { logError, logInfo } from "@cashbook/util-logging"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  ArrowLeftIcon,
  CancelIcon,
  Inline,
  Text,
  Box,
} from "@cashbook/web-components"
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import toast from "react-hot-toast"

type T_Before_Install_Prompt_Event = Event & {
  prompt: () => void
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>
}

type Installed_Via = false | "pwa" | "ms_store"

const WebAppInstallContext = createContext<{
  installedVia: Installed_Via
}>({
  installedVia: false,
})

export function useInstallContext() {
  return useContext(WebAppInstallContext)
}

export function AppInstallProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const eventRef = useRef<T_Before_Install_Prompt_Event | null>(null)
  const [installedVia, setInstalledVia] = useState(() => {
    const installedVia = getWebAppInstalledVia()
    switch (installedVia) {
      case "ms_store":
        trackEvent(TrackingEvents.WEB_APP_OPENED_VIA_DESKTOP_INSTALL)
        break
      case "pwa":
        trackEvent(TrackingEvents.WEB_APP_OPENED_VIA_PWA_INSTALL)
        break
      default:
        if (installedVia) {
          logInfo(`Mutated installation method to ${installedVia}`)
          return false
        }
    }
    return installedVia
  })
  useEffect(() => {
    function handleBeforeInstall(event: T_Before_Install_Prompt_Event) {
      if (!shouldShowInstallPrompt()) {
        return
      }
      eventRef.current = event
      event.preventDefault()
      // browser notifies the app when it's about to show the "add to home screen" prompt
      trackEvent(TrackingEvents.WEB_APP_ELIGIBLE_FOR_INSTALL)
      toast(
        (t) => (
          <>
            <Inline gap="4" alignItems="center">
              <Text fontWeight="medium">Install Web App</Text>
              <Box
                as="button"
                onClick={() => {
                  storePromptAcceptance()
                  toast.dismiss(t.id)
                  // track that the user wanted to install the app
                  trackEvent(TrackingEvents.WEB_APP_INSTALL_PROMPT_ACCEPTED)
                  GTagManager.webAppInstall()
                  // track the prompt shown
                  eventRef.current?.prompt()
                  // additionally, we wait for the prompt's outcome (either "accepted" or "dismissed") and also record it
                  eventRef.current?.userChoice.then((choice) => {
                    if (choice?.outcome === "accepted") {
                      clearAllStoredPreferences()
                      setInstalledVia("pwa")
                      try {
                        window.sessionStorage.setItem(
                          "web_app_installed_via",
                          "pwa"
                        )
                      } catch (e) {
                        logError(e)
                      }
                      trackEvent(
                        TrackingEvents.WEB_APP_INSTALL_NATIVE_ACCEPTED,
                        {
                          choice: choice?.outcome,
                        }
                      )
                    } else {
                      trackEvent(
                        TrackingEvents.WEB_APP_INSTALL_NATIVE_REJECTED,
                        {
                          choice: choice?.outcome,
                        }
                      )
                    }
                  })
                }}
                bgColor="blue900"
                paddingX="2"
                paddingY="1"
                rounded="md"
              >
                <ArrowLeftIcon rotate="180" size="4" color="white" />
              </Box>
              <Box
                as="button"
                onClick={() => {
                  toast.dismiss(t.id)
                  storeRejection()
                  trackEvent(TrackingEvents.WEB_APP_INSTALL_PROMPT_REJECTED)
                }}
                padding="1"
                rounded="md"
              >
                <CancelIcon size="4" />
              </Box>
            </Inline>
          </>
        ),
        {
          duration: Infinity,
          id: "appInstallPrompt",
        }
      )
    }
    if (typeof window !== "undefined") {
      window.addEventListener(
        "beforeinstallprompt",
        handleBeforeInstall as (e: Event) => void
      )
      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstall as (e: Event) => void
        )
      }
    }
  }, [])
  const context = useMemo(() => ({ installedVia }), [installedVia])
  return (
    <WebAppInstallContext.Provider value={context}>
      {children}
    </WebAppInstallContext.Provider>
  )
}

function shouldShowInstallPrompt() {
  if (getLastRejectionAt() || getLastPromptAcceptanceAt()) {
    return false
  }
  return true
}

const WEB_APP_MANIFEST_UTM_SOURCE = "homescreen"
const MS_WINDOWS_REFERER = "app-info://platform/microsoft-store"

function getWebAppInstalledVia(): Installed_Via {
  if (typeof window === "undefined") return false
  const location = window.location
  const search = location.search
  const searchParams = new URLSearchParams(search)
  const utmSource = searchParams.get("utm_source")
  const referrer = document.referrer
  let installedVia: Installed_Via = false
  if (referrer === MS_WINDOWS_REFERER) {
    installedVia = "ms_store"
  } else if (utmSource === WEB_APP_MANIFEST_UTM_SOURCE) {
    installedVia = "pwa"
  }
  if (installedVia) {
    try {
      window.sessionStorage.setItem("web_app_installed_via", installedVia)
    } catch (e) {
      logError(e)
    }
  } else {
    try {
      installedVia = (window.sessionStorage.getItem("web_app_installed_via") ||
        false) as typeof installedVia
    } catch (e) {
      logError(e)
    }
  }
  return installedVia
}

function storeRejection() {
  // last web app install rejection at
  window.localStorage.setItem("lwair_at", new Date().toISOString())
}

function getLastRejectionAt() {
  // last web app install rejection at
  const at = window.localStorage.getItem("lwair_at")
  if (at) {
    try {
      return new Date(at)
    } catch (e) {
      console.log("Invalid 'last web app install' rejection at")
    }
  }
  return undefined
}

function storePromptAcceptance() {
  try {
    // last web app install prompt accepted at
    window.localStorage.setItem("lwaipa_at", new Date().toISOString())
  } catch (e) {
    logError(e)
  }
}

function clearAllStoredPreferences() {
  try {
    window.localStorage.removeItem("lwair_at")
    window.localStorage.removeItem("lwaipa_at")
  } catch (e) {
    logError(e)
  }
}

function getLastPromptAcceptanceAt() {
  try {
    // last web app install prompt accepted at
    const at = window.localStorage.getItem("lwaipa_at")
    if (at) {
      try {
        return new Date(at)
      } catch (e) {
        logError(e)
        console.log("Invalid 'Last web app install prompt' accepted at")
      }
    }
  } catch (e) {
    logError(e)
  }
  return undefined
}
