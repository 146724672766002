import { Channel } from "./contract"

declare global {
  class clevertap {
    static privacy: Array<unknown>
    static event: Array<unknown>
    static onUserLogin: Array<unknown>
    static track(...args: unknown[]): void
    static clear(): void
    static logout(): void
    static identify(...args: unknown[]): void
  }
}

const createClevertapChannel: Channel = function createClevertapChannel() {
  return {
    page() {
      return
    },
    track(eventName: string, properties?: object) {
      clevertap.event.push(eventName, properties || {})
    },
    reset() {
      clevertap.clear()
      clevertap.logout()
    },
    identify(userId: string, properties: object) {
      clevertap.onUserLogin.push({
        Site: {
          Identity: userId,
          ...properties,
        },
      })
    },
  }
}

export default createClevertapChannel
