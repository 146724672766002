import { Channel } from "./contract"

declare global {
  class analytics {
    static page(...args: unknown[]): void
    static track(...args: unknown[]): void
    static reset(): void
    static identify(...args: unknown[]): void
  }
}

const createMixpanelChannel: Channel = function createMixpanelChannel() {
  return {
    page(...args) {
      analytics.page(...args)
    },
    track(...args) {
      analytics.track(...args)
    },
    reset() {
      analytics.reset()
    },
    identify(...args) {
      analytics.identify(...args)
    },
  }
}

export default createMixpanelChannel
