const version = process.env.REACT_APP_VERSION || "1.0.0"

const config = {
  /**
   * Public url of the application
   * This is used to prefix the links of assets generated by build process. This can be set to "/" when the website
   * is hosted on rooted domain
   */
  publicUrl: process.env.PUBLIC_URL,
  /**
   * Application environment
   */ appEnv: process.env.REACT_APP_ENV,
  /**
   * Is application under development environment
   */ dev: process.env.NODE_ENV === "development",
  /**
   * Application domain url
   */
  appUrl: process.env.REACT_APP_URL,
  /**
   * Name/Code Name of the application
   */
  appName: "@cashbook/web",
  /**
   * Version of the application
   */
  appVersion: version,
  /**
   * Title of the application
   */
  appTitle: process.env.REACT_APP_TITLE,
  /**
   * Mobile application download link
   */
  appDownloadLink: process.env.REACT_APP_DOWNLOAD_LINK,
  /**
   * Error reporting configuration
   */
  errorReporting: {
    sentry: {
      dsn: process.env.REACT_APP_SENTRY_DSN,
    },
  },
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    cloudFunctionsRegion: process.env.REACT_APP_CLOUD_FUNCTIONS_REGION,
    cloudFunctionsUSRegion: process.env.REACT_APP_CLOUD_FUNCTIONS_REGION_US,
    supportPhoneNumberKey: "support_number",
  },
  /**
   * Support email
   */
  supportEmail: "team@cashbook.in",
  /**
   * Support email
   */
  supportPhoneNumber: "+918217315827",
  /**
   * Terms of service url
   */
  tosUrl: "https://cashbookapp.in/legal/terms",
  /**
   * Privacy policy url
   */
  privacyPolicyUrl: "https://cashbookapp.in/legal/privacy",
  /**
   * Google tag manager
   */
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  cleverTapId: process.env.REACT_APP_CLEVER_TAP_ID,

  //Links for app download
  appDownloadFromBills: "https://cbook.page.link/bills",
  appStoreDownloadLink:
    "https://apps.apple.com/app/apple-store/id1632006509?pt=125235767&ct=Web%20Banner&mt=8",

  //Links to youtube helping videos
  howToUseCB: "https://youtu.be/NZNIf8kFHdo",
  businessLayerYt: "https://youtu.be/YSGAiZ9mGHE",

  //Payments announcement
  paymentsWebPage: "https://cashbook.in/payments/",

  //Payments
  paymentsApiBaseUrl: process.env.REACT_APP_PAYMENTS_API_BASE_URL,
  paymentsHashKey: process.env.REACT_APP_PAYMENT_API_HASH_KEY,
}

export default config
