import {
  endOfDay,
  formatDate,
  isBeforeDate,
  startOfDay,
  timeStampToDate,
  isSameDay,
} from "@cashbook/util-dates"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Button,
  CancelIcon,
  CloudUpload,
  DatePicker,
  DocumentDownloadIcon,
  ExcelFileIcon,
  GearIcon,
  getButtonClassName,
  InformationCircleIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MinusIcon,
  Modal,
  ModalBody,
  ModalFooter,
  MultiSearchSelect,
  PageMeta,
  PDFIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  SearchSelect,
  Heading,
  Stack,
  Inline,
  Text,
  Time,
  Tooltip,
  TransactionDate,
  TrashIcon,
  useOverlayTriggerState,
  UserAddIcon,
  Box,
  DataLoadingFallback,
  CloudOffIcon,
  PassbookIcon,
  ArrowLeftIcon,
  DateSelect,
  ArrowRightIcon,
  MoveIcon,
  ArrowDropDownIcon,
  ExchangeIcon,
  CopyIcon,
  PlusMinus,
  CategoryIcon,
  UsersIcon,
  PaymentModeIcon,
  PaymentsLightIcon,
  Circle,
} from "@cashbook/web-components"
import React, { useEffect, useMemo, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  TBook,
  BOOK_PERMISSIONS,
  useBook,
  isTransactionUpdated,
  useTransactionsSearch,
  resolveAppliedDateInterval,
} from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import ErrorBoundary from "../ErrorBoundary"
import { Amount } from "../support/Intl"
import {
  ExportTransactionsInModal,
  LogTransactionInDialog,
  TransactionDetailsInDialog,
} from "../Transactions"
import {
  DeleteTransactions,
  EditTransactionInDialog,
} from "../Transactions/Log"
import { getAllEntryFields } from "../Books/EntryFields"
import { usePartyOrContact } from "@cashbook/data-store/books"
import { BookRolesAndPermissions } from "../Books"
import { useBusiness } from "@cashbook/data-store/businesses"
import { BillImages, BillImage } from "../Transactions"
import { pluralize } from "@cashbook/util-general"
import classNames from "classnames"
import { PerformActionInDialog } from "../Transactions/TransferActions"
import PerformChangeFieldsInModal from "../Transactions/ChangeFields"
import { Checkbox, Radio } from "../common"
import config from "../config"
import { useSyncedStorageState } from "@cashbook/data-store/storage"

export default function BookTransactionsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details" />}
        traceId="loading_book_details"
      >
        <BookTransactions
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

type CUSTOM_FIELDS = { [key: string]: string }
function getCustomFieldValuesFromTransaction(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any,
  fields: string[]
): CUSTOM_FIELDS {
  const fieldObj: { [key: string]: string } = {}
  fields
    .filter((field) => t?.[field])
    .forEach((key) => {
      fieldObj[key] = t?.[key]
    })
  return fieldObj
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCustomFieldsDataForDisplay(t: any, fields: CUSTOM_FIELDS): string {
  let customs = ""
  Object.keys(fields).forEach((key, i) => {
    if (t?.[key]) {
      customs += `${i > 0 ? ", " : ""} ${fields[key]}: ${t?.[key]}`
    }
  })
  return customs
}

function BookTransactions({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book } = useBook(bookId)
  return (
    <>
      <PageMeta>
        <title>{book.name}'s Transactions</title>
      </PageMeta>
      <ErrorBoundary key={bookId}>
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading entries..." />}
          traceId="loading_transactions"
        >
          <Transactions book={book} businessId={businessId} />
        </SuspenseWithPerf>
      </ErrorBoundary>
    </>
  )
}

const from = "filters"

function toggleElementInArray(arr: string[], element: string) {
  const index = arr?.length ? arr.indexOf(element) : -1 // or use arr.includes(element) for modern browsers
  if (index !== -1) {
    // Element is already in the array, remove it
    arr.splice(index, 1)
  } else {
    // Element is not in the array, add it
    arr.push(element)
  }
  return arr
}

const ENTRY_TYPE = [
  {
    id: "all",
    label: "All",
  },
  {
    id: "cash-in",
    label: "Cash In",
  },
  {
    id: "cash-out",
    label: "Cash Out",
  },
]

function Transactions({
  book: bookProp,
  businessId,
}: {
  book: TBook
  businessId: string
}) {
  const { authTeamMemberDetails } = useBusiness(businessId)
  const [searchParams] = useSearchParams()
  const q = searchParams.get("q") || undefined
  const {
    params,
    transactions,
    totalTransactions,
    handleParamChange,
    setParamValue,
    resetParams,
    hasAppliedFilters,
    openingBalance,
    totalCashIn,
    totalCashOut,
    closingBalance,
    hasFilterAppliedOtherThanDate,
    numberOfFiltersApplied,
    totalFilteredTransactions,
    closingBalancePerTransaction,
    resolveAttributesFromBook,
    resolveTransactionAttributesFromInvolvedUsers,

    lastPage,
    pagination,
    currentPage,
    next,
    setPage,
    previous,
  } = useTransactionsSearch(bookProp, { q }, authTeamMemberDetails.role.id)
  const {
    book,
    involvedUsers,
    checkIfAuthenticatedMemberCan,
    authMemberDetails,
    isShared,
  } = useBook(bookProp.id)
  const { user } = useProfile()
  const { parties, categories, paymentModes } = book

  const { afterDate, beforeDate, isOpeningBalanceEnabled } = params

  const resolvedDateIntervals = useMemo(
    () => resolveAppliedDateInterval([afterDate, beforeDate]),
    [afterDate, beforeDate]
  )

  const canUpdateTransactions = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.EDIT_ENTRY
  )
  const canAddMembers = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ADD_MEMBER
  )
  const canDeleteTransactions = canUpdateTransactions
  const role = authMemberDetails.role.id
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()
  const { trackEntryFieldSettingEvent } = getAllEntryFields(
    book,
    partyOrContact
  )

  const [newFeaturesIntroduced] = useSyncedStorageState<{
    [key: string]: string[]
  }>("newFeatures", {})

  const showNewBannerForSettingsIcon: string | undefined = useMemo(() => {
    return !newFeaturesIntroduced[user?.uid]?.includes("fields") ||
      !newFeaturesIntroduced[user?.uid]?.includes("mandatoryCategory") ||
      !newFeaturesIntroduced[user?.uid]?.includes("mandatoryPaymentMode")
      ? "fields"
      : undefined
  }, [newFeaturesIntroduced, user?.uid])

  const optionsForDateFilter = useMemo(() => {
    return [
      {
        id: "all",
        label: "All Time",
        value: [undefined, undefined] as [undefined | Date, undefined | Date],
      },
    ]
      .concat(
        resolvedDateIntervals.intervalsWithAppliedStatus.map((i) => ({
          id: i.label,
          label: i.label,
          value: [i.interval[0], i.interval[1]],
        }))
      )
      .concat([
        {
          id: "custom",
          label:
            resolvedDateIntervals.appliedDateIntervalLabel === "Custom" &&
            afterDate &&
            beforeDate
              ? isSameDay(afterDate, beforeDate)
                ? `${formatDate(afterDate, "dd MMM yy")}`
                : `${
                    afterDate ? formatDate(afterDate, "dd MMM yy") : "Start"
                  } - ${
                    beforeDate ? formatDate(beforeDate, "dd MMM yy") : "Now"
                  }`
              : "Custom",
          value: [undefined, undefined],
        },
      ])
  }, [
    afterDate,
    beforeDate,
    resolvedDateIntervals.appliedDateIntervalLabel,
    resolvedDateIntervals.intervalsWithAppliedStatus,
  ])

  const selectedDateFilter = useMemo(() => {
    if (resolvedDateIntervals.customDateApplied) {
      return {
        id: "custom",
        label:
          resolvedDateIntervals.appliedDateIntervalLabel === "Custom" &&
          afterDate &&
          beforeDate
            ? isSameDay(afterDate, beforeDate)
              ? `${formatDate(afterDate, "dd MMM yy")}`
              : `${
                  afterDate ? formatDate(afterDate, "dd MMM yy") : "Start"
                } - ${beforeDate ? formatDate(beforeDate, "dd MMM yy") : "Now"}`
            : "Custom",
        value: [afterDate, beforeDate],
      }
    }
    const selectedOption = optionsForDateFilter.find(
      (option) =>
        option.label === resolvedDateIntervals.appliedDateIntervalLabel
    )
    return selectedOption
  }, [
    afterDate,
    beforeDate,
    optionsForDateFilter,
    resolvedDateIntervals.appliedDateIntervalLabel,
    resolvedDateIntervals.customDateApplied,
  ])

  const customDateFilterType: "dateRange" | "singleDay" = useMemo(() => {
    if (resolvedDateIntervals.customDateApplied) {
      if (afterDate && beforeDate) {
        if (isSameDay(afterDate, beforeDate)) {
          return "singleDay"
        } else {
          return "dateRange"
        }
      } else if (afterDate) {
        return "singleDay"
      } else {
        return "dateRange"
      }
    }
    return "dateRange"
  }, [afterDate, beforeDate, resolvedDateIntervals.customDateApplied])

  const canDeleteEntries = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.DELETE_ENTRY
  )

  const canMoveEntries = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.MOVE_ENTRIES
  )
  const canCopyEntries = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.COPY_ENTRIES
  )
  const canCopyOpposite = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES
  )

  const showSelectionBox =
    canDeleteEntries ||
    canUpdateTransactions ||
    canMoveEntries ||
    canCopyEntries ||
    canCopyOpposite

  const [selectedTransactions, setSelectedTransactions] = useState<{
    [key: string]: string[]
  }>({})

  const selectedTransactionsOnThisPage =
    selectedTransactions[`page_${currentPage}`]

  useEffect(() => {
    if (hasAppliedFilters) {
      setSelectedTransactions({})
    }
  }, [hasAppliedFilters])

  const customFieldKeys: string[] = useMemo(() => {
    return Object.keys(book?.customFields || {}).map((key) => key)
  }, [book?.customFields])
  const customFieldNames: { [key: string]: string } = useMemo(() => {
    const customFieldNamesObj: { [key: string]: string } = {}
    Object.keys(book?.customFields || {})
      .sort(
        (a, b) =>
          (book?.customFields?.[a].order || 0) -
          (book?.customFields?.[b].order || 0)
      )
      .forEach((key) => {
        customFieldNamesObj[key] = book?.customFields?.[key].name || ""
      })
    return customFieldNamesObj
  }, [book?.customFields])

  return (
    <Stack gap="4" paddingX="4">
      <Inline
        gap="6"
        alignItems="center"
        marginX="4"
        paddingY="4"
        borderBottomWidth="1"
        className="min-h-[62px]"
      >
        <Inline gap="6" alignItems="center">
          <Box>
            <Link to={`/businesses/${businessId}/cashbooks/`}>
              <ArrowLeftIcon />
            </Link>
          </Box>
          <Box maxWidth="xl">
            <Heading
              as="h2"
              fontSize="lg"
              fontWeight="semibold"
              title={`${book.name}`}
              className="text-ellipsis"
            >
              {book.name}
            </Heading>
          </Box>
          <Link
            to={`/businesses/${businessId}/cashbooks/${book.id}/settings`}
            className={getButtonClassName({ inline: true })}
          >
            <Tooltip event="onHover" content="Book Settings">
              <Box className="relative">
                <GearIcon />
                {showNewBannerForSettingsIcon ? (
                  <Circle
                    size="2"
                    backgroundColor="iconError"
                    className="absolute top-[0px] right-[2px]"
                  />
                ) : null}
              </Box>
            </Tooltip>
          </Link>
        </Inline>
        {canAddMembers ? (
          <Box borderLeftWidth="1" paddingLeft="6">
            <Link
              to={`/businesses/${businessId}/cashbooks/${book.id}/settings/members`}
              className={getButtonClassName({ inline: true })}
            >
              <Tooltip event="onHover" content="Add Member">
                <UserAddIcon />
              </Tooltip>
            </Link>
          </Box>
        ) : null}
        <Inline flex="1" justifyContent="end">
          <Inline gap="6" alignItems="center">
            {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_CASH_IN_OUT) ? (
              <Link
                to={`/businesses/${businessId}/cashbooks/${book.id}/import-transactions`}
                className={getButtonClassName({
                  level: "tertiary",
                })}
              >
                <CloudUpload size="6" />
                Add Bulk Entries
              </Link>
            ) : null}
            {checkIfAuthenticatedMemberCan(
              BOOK_PERMISSIONS.DOWNLOAD_REPORTS
            ) ? (
              <ExportTransactionsInModal
                searchParams={params}
                book={book}
                involvedUsers={involvedUsers}
              >
                {({ onExport }) => (
                  <Menu>
                    <Tooltip event="onHover" content="PDF and Excel Reports">
                      <MenuButton className="relative">
                        <Inline as="span" gap="2" alignItems="center">
                          <DocumentDownloadIcon size="5" />
                          <Text as="span">Reports</Text>
                        </Inline>
                      </MenuButton>
                    </Tooltip>
                    <MenuList align="bottom-right">
                      <MenuItem
                        action="pdf-export"
                        className="whitespace-pre"
                        onClick={() => onExport("pdf")}
                      >
                        <Inline gap="2" alignItems="center">
                          <PDFIcon size="6" color="gray500" />
                          <Text as="span">PDF Report</Text>
                        </Inline>
                      </MenuItem>
                      <MenuItem
                        action="excel-export"
                        className="whitespace-pre"
                        onClick={() => onExport("excel")}
                      >
                        <Inline gap="2" alignItems="center">
                          <ExcelFileIcon size="6" color="gray500" />
                          <Text as="span">Excel Report</Text>
                        </Inline>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </ExportTransactionsInModal>
            ) : null}
          </Inline>
        </Inline>
      </Inline>
      <Stack gap="8" padding="4">
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack gap="8">
            <Inline gap="8" flexWrap="wrap">
              <Inline>
                <Box>
                  <SelectCustomDate
                    value={[params.afterDate, params.beforeDate]}
                    isOpeningBalanceEnabled={isOpeningBalanceEnabled}
                    appliedCustomFilterType={customDateFilterType}
                    wasCustomApplied={resolvedDateIntervals.customDateApplied}
                    onChange={([afterDate, beforeDate], isEnabled) => {
                      setParamValue("afterDate", afterDate)
                      setParamValue("beforeDate", beforeDate)
                      setParamValue(
                        "isOpeningBalanceEnabled",
                        Boolean(isEnabled)
                      )
                    }}
                  >
                    {({ onSelect }) => {
                      return (
                        <DateSelect
                          hasValue={!resolvedDateIntervals.allTimeApplied}
                          value={selectedDateFilter}
                          options={optionsForDateFilter}
                          onApply={(option, isEnabled) => {
                            setParamValue("afterDate", option?.value[0])
                            setParamValue("beforeDate", option?.value[1])
                            setParamValue("isOpeningBalanceEnabled", isEnabled)
                          }}
                          onChange={(option) => {
                            if (option?.id === "custom") {
                              onSelect()
                            }
                          }}
                          isOpeningBalanceEnabled={isOpeningBalanceEnabled}
                          label={
                            resolvedDateIntervals.allTimeApplied
                              ? "Duration: All Time"
                              : resolvedDateIntervals.customDateApplied
                              ? afterDate &&
                                beforeDate &&
                                isSameDay(afterDate, beforeDate)
                                ? `${formatDate(afterDate, "dd MMM yy")}`
                                : `${
                                    afterDate
                                      ? formatDate(afterDate, "dd MMM yy")
                                      : "Start"
                                  } - ${
                                    beforeDate
                                      ? formatDate(beforeDate, "dd MMM yy")
                                      : "Now"
                                  }`
                              : `${resolvedDateIntervals.appliedDateIntervalLabel}`
                          }
                        />
                      )
                    }}
                  </SelectCustomDate>
                </Box>
              </Inline>
              <Inline>
                <SearchSelect
                  searchDisabled
                  hasValue={Boolean(params.type)}
                  label={
                    !params.type
                      ? "Types: All"
                      : params.type === "cash-in"
                      ? "Cash In"
                      : "Cash Out"
                  }
                  onChange={(option) => {
                    setParamValue(
                      "type",
                      option && option.id !== "all" ? option.id : undefined
                    )
                  }}
                  options={ENTRY_TYPE}
                  value={params.type ? params.type : "all"}
                />
              </Inline>
              {!book.preferences?.partyDisabled && (
                <Inline>
                  <MultiSearchSelect
                    label={
                      !params.parties?.length
                        ? `${partiesOrContacts}: All`
                        : `${partiesOrContacts}: (${params.parties.length})`
                    }
                    searchPlaceholder={`Search ${partiesOrContacts}...`}
                    onChange={(value) => {
                      setParamValue("parties", value)
                    }}
                    options={parties?.map((party) => ({
                      ...party,
                      id: party.uuid,
                      label: party.name,
                    }))}
                    helpOnEmptyResults={
                      <>
                        <div className="font-semibold mb-2">
                          No {partyOrContact} Added!
                        </div>
                        <p className="text-gray-500 tracking-wide">
                          You can add {partiesOrContacts} for this book from{" "}
                          <Link
                            onClick={() =>
                              trackEntryFieldSettingEvent("parties", from)
                            }
                            to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields/parties`}
                          >
                            <Text
                              as="span"
                              color="blue900"
                              fontWeight="semibold"
                            >
                              book settings page <GearIcon size="4" />.
                            </Text>
                          </Link>
                        </p>
                      </>
                    }
                    searchDisabled={!parties?.length}
                    value={params.parties?.map((party) => ({
                      ...party,
                      id: party.uuid,
                      label: party.name,
                    }))}
                  />
                </Inline>
              )}
              {(role === "editor"
                ? !book.preferences?.hideEntriesByOthers &&
                  involvedUsers.length > 0
                : involvedUsers.length > 0) && (
                <Inline>
                  <SearchSelect
                    label={
                      !params.entryBy
                        ? "Members: All"
                        : `${params.entryBy.name}`
                    }
                    searchPlaceholder="Search Members"
                    onChange={(option) => {
                      setParamValue("entryBy", option)
                    }}
                    options={involvedUsers.map((member) => ({
                      id: member.id,
                      name: member.name,
                      label: member.id === user.uid ? "You" : member.name,
                      phoneNumber: member.phoneNumber,
                    }))}
                    value={params.entryBy ? params.entryBy.id : null}
                  />
                </Inline>
              )}
              {!book.preferences?.paymentModesDisabled ? (
                <Inline>
                  <MultiSearchSelect
                    label={
                      !params.paymentModes?.length
                        ? "Payment Modes: All"
                        : `Payment Modes: (${params.paymentModes.length})`
                    }
                    searchPlaceholder="Search Payment Modes..."
                    onChange={(value) => {
                      setParamValue("paymentModes", value)
                    }}
                    options={paymentModes?.map((mode) => ({
                      ...mode,
                      id: mode.uuid,
                      label: mode.name,
                    }))}
                    helpOnEmptyResults={
                      <>
                        <div className="font-semibold mb-2">
                          No Payment Modes Added!
                        </div>
                        <p className="text-gray-500 tracking-wide">
                          You can create payment modes for this book using{" "}
                          <Box
                            as={Link}
                            onClick={() =>
                              trackEntryFieldSettingEvent("payment-modes", from)
                            }
                            to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields/payment-modes`}
                          >
                            <Text
                              as="span"
                              color="blue900"
                              fontWeight="semibold"
                            >
                              book settings page <GearIcon size="4" />.
                            </Text>
                          </Box>
                        </p>
                      </>
                    }
                    searchDisabled={!paymentModes?.length}
                    value={params.paymentModes?.map((mode) => ({
                      ...mode,
                      id: mode.uuid,
                      label: mode.name,
                    }))}
                  />
                </Inline>
              ) : null}
              {!book.preferences?.categoriesDisabled ? (
                <Inline>
                  <MultiSearchSelect
                    label={
                      !params.categories?.length
                        ? "Categories: All"
                        : `Categories: (${params.categories.length})`
                    }
                    searchPlaceholder="Search Categories..."
                    onChange={(value) => {
                      setParamValue("categories", value)
                    }}
                    options={categories?.map((category) => ({
                      ...category,
                      id: category.uuid,
                      label: category.name,
                    }))}
                    helpOnEmptyResults={
                      <>
                        <div className="font-semibold mb-2">
                          No Categories Added!
                        </div>
                        <p className="text-gray-500 tracking-wide">
                          You can create categories for this book from{" "}
                          <Box
                            as={Link}
                            onClick={() =>
                              trackEntryFieldSettingEvent("categories", from)
                            }
                            to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields/categories`}
                          >
                            <Text
                              as="span"
                              color="blue900"
                              fontWeight="semibold"
                            >
                              book settings page <GearIcon size="4" />.
                            </Text>
                          </Box>
                        </p>
                      </>
                    }
                    searchDisabled={!categories?.length}
                    value={params.categories?.map((category) => ({
                      ...category,
                      id: category.uuid,
                      label: category.name,
                    }))}
                  />
                </Inline>
              ) : null}
              {hasAppliedFilters ? (
                <Button
                  inline
                  onClick={() => {
                    resetParams()
                    trackEvent(TrackingEvents.CLEAR_ALL_FILTERS_CLICKED, {
                      numberOfFilters: numberOfFiltersApplied,
                    })
                  }}
                  level="tertiary"
                  size="sm"
                >
                  <Box>
                    <CancelIcon size="6" />
                  </Box>
                  <Text color="textPrimary" fontSize="bt">
                    Clear All
                  </Text>
                </Button>
              ) : null}
            </Inline>
            <Inline gap="4" justifyContent="between">
              <Box flex="1">
                <Inline
                  position="relative"
                  rounded="md"
                  height="10"
                  paddingRight="2"
                  alignItems="stretch"
                  gap="2"
                  maxWidth="lg"
                  width="full"
                  borderWidth="1"
                  className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
                >
                  <input
                    type="search"
                    name="q"
                    placeholder="Search by remark..."
                    value={params.q}
                    onChange={handleParamChange}
                    disabled={!totalTransactions}
                    className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                    onFocus={() => {
                      trackEvent(TrackingEvents.SET_SEARCH_FOCUS)
                    }}
                  />
                  <Inline
                    as="button"
                    type="button"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => {
                      if (params.q) setParamValue("q", "")
                    }}
                  >
                    {params.q ? (
                      <CancelIcon color="gray900" />
                    ) : (
                      <SearchIcon color="gray500" />
                    )}
                  </Inline>
                </Inline>
              </Box>
              <Box>
                <CashInCashOutButton
                  book={book}
                  autoFocus={!totalTransactions}
                />
              </Box>
            </Inline>
            <Box display="none">
              <Button type="reset" onClick={() => resetParams()}>
                Reset
              </Button>
            </Box>
          </Stack>
        </form>
        {transactions.length &&
        checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.VIEW_NET_BALANCE) ? (
          <Box>
            <Overview
              totalCashIn={totalCashIn}
              totalCashOut={totalCashOut}
              closingBalance={closingBalance}
              openingBalance={
                params.afterDate &&
                !hasFilterAppliedOtherThanDate &&
                isOpeningBalanceEnabled
                  ? openingBalance
                  : undefined
              }
            />
          </Box>
        ) : null}
        {transactions.length ? (
          <Stack>
            <Inline
              gap="10"
              alignItems="center"
              justifyContent="between"
              position="sticky"
              top="0"
              paddingY="4"
              className="z-[2]"
              backgroundColor="surfaceDefault"
            >
              <Inline alignItems="center" gap="2">
                <Text color="textMedium" fontSize="b3">
                  Showing {pagination.skip + 1} -{" "}
                  {currentPage === lastPage
                    ? totalFilteredTransactions
                    : pagination.take * currentPage}{" "}
                  of {totalFilteredTransactions}{" "}
                  {pluralize("entry", totalFilteredTransactions)}
                </Text>
                {selectedTransactionsOnThisPage?.length ? (
                  <Box
                    borderLeftWidth="1"
                    borderColor="borderOutline"
                    paddingLeft="2"
                  >
                    <Text fontSize="b3">
                      {selectedTransactionsOnThisPage?.length} selected in this
                      page
                    </Text>
                  </Box>
                ) : null}
              </Inline>
              <Inline gap="10" alignItems="center">
                <Inline gap="2" alignItems="center">
                  <Menu>
                    <MenuButton inline>
                      <Inline
                        gap="3"
                        alignItems="center"
                        cursor="pointer"
                        paddingX="2"
                        rounded="md"
                        paddingLeft="3"
                        paddingRight="1"
                        height="8"
                        justifyContent="center"
                        borderWidth="1"
                        color="textHigh"
                        borderColor="borderOutline"
                      >
                        <Inline alignItems="center" gap="1">
                          <Text fontSize="c2">Page {currentPage}</Text>
                          <ArrowDropDownIcon size="5" />
                        </Inline>
                      </Inline>
                    </MenuButton>
                    <MenuList align="bottom-right">
                      <Box className={`overflow-auto max-h-[300px] w-[200px]`}>
                        {Array.from(
                          { length: lastPage || 4 },
                          (_, i) => i + 1
                        ).map((item) => {
                          const isSelected = currentPage === item
                          return (
                            <MenuItem
                              key={item}
                              action={`page=${item}`}
                              className={classNames("py-3 my-2", {
                                "bg-[#EDEFFB] cursor-not-allowed": isSelected,
                              })}
                              onClick={() => {
                                if (isSelected) return
                                setPage(item)
                              }}
                            >
                              <Inline alignItems="center" gap="3">
                                <Radio isSelected={isSelected} />
                                <Text fontSize="b3">Page {item}</Text>
                              </Inline>
                            </MenuItem>
                          )
                        })}
                      </Box>
                    </MenuList>
                  </Menu>
                  <Text fontSize="b3">of {lastPage}</Text>
                </Inline>
                <Inline gap="4">
                  <Box
                    size="8"
                    rounded="md"
                    borderWidth="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderColor="borderOutline"
                    backgroundColor={
                      currentPage === 1 ? "surfaceNeutralLowest" : "transparent"
                    }
                    cursor="pointer"
                    className={classNames({
                      "cursor-not-allowed": currentPage === 1,
                    })}
                    onClick={() => (currentPage === 1 ? undefined : previous())}
                  >
                    <ArrowRightIcon
                      color={currentPage === 1 ? "iconMedium" : "iconHigh"}
                      className="rotate-180"
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    size="8"
                    rounded="md"
                    borderWidth="1"
                    onClick={() =>
                      currentPage === lastPage ? undefined : next()
                    }
                    cursor="pointer"
                    className={classNames({
                      "cursor-not-allowed": currentPage === lastPage,
                    })}
                    backgroundColor={
                      currentPage === lastPage
                        ? "surfaceNeutralLowest"
                        : "transparent"
                    }
                    borderColor="borderOutline"
                  >
                    <ArrowRightIcon
                      color={
                        currentPage === lastPage ? "iconMedium" : "iconHigh"
                      }
                    />
                  </Box>
                </Inline>
              </Inline>
            </Inline>
            <Box
              as="table"
              width="full"
              position="relative"
              fontWeight="medium"
            >
              <Box
                as="thead"
                bgColor="backgroundLight3"
                fontWeight="semibold"
                color="gray500"
                fontSize="sm"
                className="whitespace-pre"
              >
                <tr>
                  {showSelectionBox ? (
                    <Box
                      className="w-[40px] z-[1] top-16"
                      as="th"
                      position="sticky"
                      bgColor="backgroundLight3"
                      paddingX="3"
                      paddingY="4"
                    >
                      <Inline
                        gap="2"
                        justifyContent="center"
                        alignItems="center"
                        className="align-middle"
                      >
                        <Checkbox
                          size="4"
                          isSelected={
                            selectedTransactionsOnThisPage?.length ===
                            transactions.length
                          }
                          onClick={() => {
                            setSelectedTransactions((prev) => {
                              return {
                                ...prev,
                                [`page_${currentPage}`]:
                                  selectedTransactionsOnThisPage?.length ===
                                  transactions.length
                                    ? []
                                    : transactions.map((t) => t.id),
                              }
                            })
                          }}
                        />
                        {selectedTransactionsOnThisPage?.length ? (
                          <Box
                            position="absolute"
                            height="full"
                            minWidth="screenMd"
                            className=" left-8"
                            backgroundColor="backgroundLight3"
                          >
                            <PerformChangeFieldsInModal
                              book={book}
                              transactions={transactions.filter((t) =>
                                selectedTransactionsOnThisPage.includes(t.id)
                              )}
                              onSuccess={() => {
                                setSelectedTransactions((prev) => {
                                  return {
                                    ...prev,
                                    [`page_${currentPage}`]: [],
                                  }
                                })
                              }}
                            >
                              {({ handleAction: changeFieldFor }) => (
                                <PerformActionInDialog
                                  bookId={book.id}
                                  businessId={businessId}
                                  transactions={transactions.filter((t) =>
                                    selectedTransactionsOnThisPage.includes(
                                      t.id
                                    )
                                  )}
                                  onSuccess={() => {
                                    setSelectedTransactions((prev) => {
                                      return {
                                        ...prev,
                                        [`page_${currentPage}`]: [],
                                      }
                                    })
                                  }}
                                >
                                  {({ handleAction }) => (
                                    <DeleteTransactions
                                      book={book}
                                      transactions={
                                        selectedTransactionsOnThisPage.length >
                                        1
                                          ? selectedTransactionsOnThisPage
                                          : undefined
                                      }
                                      onSuccess={() => {
                                        setSelectedTransactions((prev) => {
                                          return {
                                            ...prev,
                                            [`page_${currentPage}`]: [],
                                          }
                                        })
                                      }}
                                    >
                                      {({ onDelete }) => (
                                        <OptionsOnSelection
                                          canDeleteEntries={canDeleteEntries}
                                          canEditTransaction={
                                            canUpdateTransactions
                                          }
                                          partyLabel={partyOrContact}
                                          canMoveEntries={canMoveEntries}
                                          canCopyEntries={canCopyEntries}
                                          canCopyOpposite={canCopyOpposite}
                                          numberOfSelectedEntries={
                                            selectedTransactionsOnThisPage?.length
                                          }
                                          onSelectAll={() => {
                                            setSelectedTransactions((prev) => {
                                              return {
                                                ...prev,
                                                [`page_${currentPage}`]:
                                                  selectedTransactionsOnThisPage?.length ===
                                                  transactions?.length
                                                    ? []
                                                    : transactions.map(
                                                        (t) => t.id
                                                      ),
                                              }
                                            })
                                          }}
                                          onDelete={() => {
                                            if (
                                              selectedTransactionsOnThisPage.length ===
                                              1
                                            ) {
                                              onDelete(
                                                transactions.find(
                                                  (t) =>
                                                    t.id ===
                                                    selectedTransactionsOnThisPage[0]
                                                )
                                              )
                                            } else {
                                              onDelete()
                                            }
                                          }}
                                          onCopy={() => handleAction("copy")}
                                          onMove={() => {
                                            handleAction("move")
                                          }}
                                          onCopyOpposite={() => {
                                            handleAction("opposite")
                                          }}
                                          onChangeFieldsFor={changeFieldFor}
                                        />
                                      )}
                                    </DeleteTransactions>
                                  )}
                                </PerformActionInDialog>
                              )}
                            </PerformChangeFieldsInModal>
                          </Box>
                        ) : null}
                      </Inline>
                    </Box>
                  ) : null}
                  <Box
                    as="th"
                    position="sticky"
                    bgColor="backgroundLight3"
                    paddingX="3"
                    paddingY="4"
                    className="w-[130px] top-16"
                  >
                    {selectedTransactionsOnThisPage?.length ? null : (
                      <>Date &amp; Time</>
                    )}
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    bgColor="backgroundLight3"
                    paddingX="3"
                    paddingY="4"
                    className="min-w-[150px] top-16"
                  >
                    {selectedTransactionsOnThisPage?.length ? null : "Details"}
                  </Box>
                  {!book.preferences?.categoriesDisabled ? (
                    <Box
                      as="th"
                      position="sticky"
                      className="top-16"
                      bgColor="backgroundLight3"
                      paddingX="3"
                      paddingY="4"
                    >
                      {selectedTransactionsOnThisPage?.length
                        ? null
                        : "Category"}
                    </Box>
                  ) : null}
                  {!book.preferences?.paymentModesDisabled ? (
                    <Box
                      as="th"
                      position="sticky"
                      className="top-16"
                      bgColor="backgroundLight3"
                      paddingX="3"
                      paddingY="4"
                    >
                      {selectedTransactionsOnThisPage?.length ? null : "Mode"}
                    </Box>
                  ) : null}
                  <Box
                    as="th"
                    position="sticky"
                    bgColor="backgroundLight3"
                    paddingX="3"
                    paddingY="4"
                    className={`w-[80px] z-[${
                      selectedTransactionsOnThisPage ? "0" : "1"
                    }] top-16`}
                  >
                    {selectedTransactionsOnThisPage?.length ? null : "Bill"}
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    bgColor="backgroundLight3"
                    paddingX="3"
                    paddingY="4"
                    textAlign="right"
                    className="max-w-[100px] top-16"
                  >
                    {selectedTransactionsOnThisPage?.length ? null : "Amount"}
                  </Box>
                  {checkIfAuthenticatedMemberCan(
                    BOOK_PERMISSIONS.VIEW_NET_BALANCE
                  ) && (
                    <Box
                      as="th"
                      position="sticky"
                      bgColor="backgroundLight3"
                      paddingX="3"
                      paddingY="4"
                      textAlign="right"
                      className="top-16"
                    >
                      {selectedTransactionsOnThisPage?.length
                        ? null
                        : "Balance"}
                    </Box>
                  )}
                  <Box
                    className="w-[90px] z-[1] top-16"
                    as="th"
                    position="sticky"
                    bgColor="backgroundLight3"
                    paddingX="3"
                    paddingY="4"
                  ></Box>
                </tr>
              </Box>
              <Box as="tbody">
                {transactions.map((t) => {
                  resolveAttributesFromBook(t)
                  resolveTransactionAttributesFromInvolvedUsers(
                    involvedUsers,
                    t
                  )
                  const fieldsWithFieldIds =
                    getCustomFieldValuesFromTransaction(t, customFieldKeys)
                  const customFieldValues = getCustomFieldsDataForDisplay(
                    t,
                    customFieldNames
                  )
                  return (
                    <React.Fragment key={t.id}>
                      <TransactionDetailsInDialog
                        book={book}
                        transactionId={t.id}
                        businessId={businessId}
                      >
                        {({ showDetails }) => (
                          <EditTransactionInDialog
                            book={book}
                            transactionId={t.id}
                            involvedUsers={involvedUsers}
                            customFields={fieldsWithFieldIds}
                          >
                            {({ edit }) => (
                              <Box
                                as="tr"
                                borderTopWidth="1"
                                cursor="pointer"
                                className="hover:bg-blue-100 has-hover-child"
                                onClick={() => {
                                  trackEvent(TrackingEvents.ENTRY_CLICKED, {
                                    sharedBook: isShared,
                                    billAttached: Boolean(t.imageUrl),
                                    role: role,
                                    isEdited: isTransactionUpdated(t),
                                    synced: true,
                                    entryBySelf: Boolean(
                                      t.createdBy === authMemberDetails.id
                                    ),
                                  })
                                  showDetails()
                                }}
                                tabIndex={-1}
                              >
                                {showSelectionBox ? (
                                  <Box
                                    as="td"
                                    paddingX="3"
                                    paddingY="4"
                                    className="whitespace-pre"
                                    onClick={(e: {
                                      stopPropagation: () => void
                                    }) => {
                                      e.stopPropagation()
                                      setSelectedTransactions((prev) => {
                                        return {
                                          ...prev,
                                          [`page_${currentPage}`]:
                                            toggleElementInArray(
                                              selectedTransactionsOnThisPage?.length
                                                ? selectedTransactionsOnThisPage
                                                : [],
                                              t.id
                                            ),
                                        }
                                      })
                                    }}
                                  >
                                    <Box padding="1">
                                      <Checkbox
                                        size="4"
                                        isSelected={selectedTransactionsOnThisPage?.includes(
                                          t.id
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                ) : null}

                                <Box
                                  as="td"
                                  paddingX="3"
                                  paddingY="4"
                                  className="whitespace-pre"
                                >
                                  <Stack gap="2">
                                    <TransactionDate timeStamp={t.date} />
                                    <Time
                                      fontSize="sm"
                                      color="gray500"
                                      timeStamp={t.date}
                                      format="hh:mm a"
                                    />
                                  </Stack>
                                </Box>
                                <Box as="td" paddingX="3" paddingY="4">
                                  <Inline gap="2" justifyContent="between">
                                    <Stack gap="2" flex="1">
                                      <Stack
                                        gap="1"
                                        maxWidth="sm"
                                        className="break-words"
                                      >
                                        {t.party ? (
                                          <Inline gap="1">
                                            <Text
                                              title={t.party.name}
                                              className="line-clamp-2"
                                              fontWeight="semibold"
                                            >
                                              ({t.party.name})
                                            </Text>
                                            <Text
                                              color="gray500"
                                              textTransform="capitalize"
                                            >
                                              ({t.party.type})
                                            </Text>
                                          </Inline>
                                        ) : null}
                                        {customFieldValues.length ? (
                                          <Text
                                            fontSize="c3"
                                            color="textMedium"
                                            className="line-clamp-1"
                                          >
                                            {customFieldValues}
                                          </Text>
                                        ) : null}
                                        <Text
                                          className="line-clamp-1"
                                          title={t.remark || "--"}
                                        >
                                          {t.remark || "--"}
                                        </Text>
                                      </Stack>
                                      <Inline alignItems="center" gap="2">
                                        {involvedUsers.length > 1 ? (
                                          <Text
                                            fontSize="sm"
                                            color="gray500"
                                            title={
                                              t.createdAt
                                                ? `Created on ${formatDate(
                                                    timeStampToDate(
                                                      t.createdAt
                                                    ),
                                                    "dd MMM, yyyy 'at' hh:mm a"
                                                  )}`
                                                : undefined
                                            }
                                          >
                                            by{" "}
                                            {t.createdBy === user.uid
                                              ? "You"
                                              : t.createdByMember?.name}
                                          </Text>
                                        ) : null}
                                        {t.sourceLoc === "cashbook-payments" ? (
                                          <Tooltip
                                            event="onHover"
                                            content={`Copied from ${config.appTitle} Payments`}
                                          >
                                            <PaymentsLightIcon
                                              size="4"
                                              color="iconMedium"
                                            />
                                          </Tooltip>
                                        ) : t.smsBookEntryId ? (
                                          <Tooltip
                                            event="onHover"
                                            content={`Copied from passbook`}
                                          >
                                            <PassbookIcon
                                              size="4"
                                              color="iconMedium"
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </Inline>
                                    </Stack>
                                    {!t.createdAt ? (
                                      <CloudOffIcon
                                        className="animate-ping"
                                        title="Entry not backed up"
                                      />
                                    ) : null}
                                  </Inline>
                                </Box>
                                {!book.preferences?.categoriesDisabled ? (
                                  <Box
                                    as="td"
                                    paddingX="3"
                                    paddingY="4"
                                    className="p-4 break-works"
                                  >
                                    <Text>
                                      {t.categoryId ? t.category?.name : null}
                                    </Text>
                                  </Box>
                                ) : null}
                                {!book.preferences?.paymentModesDisabled ? (
                                  <Box
                                    as="td"
                                    paddingX="3"
                                    paddingY="4"
                                    className="p-4 break-works"
                                  >
                                    <Text>
                                      {t.paymentModeId
                                        ? t.paymentMode?.name
                                        : null}
                                    </Text>
                                  </Box>
                                ) : null}
                                <Box
                                  as="td"
                                  paddingX="3"
                                  paddingY="4"
                                  className="z-[1]"
                                >
                                  {t.attachments &&
                                  Object.keys(t.attachments).length ? (
                                    <BillImages attachments={t.attachments} />
                                  ) : t.thumbUrl && t.imageUrl ? (
                                    <BillImage
                                      size="8"
                                      thumbUrl={t.thumbUrl}
                                      imageUrl={t.imageUrl}
                                    />
                                  ) : null}
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="3"
                                  paddingY="4"
                                  textAlign="right"
                                  className="align-middle"
                                >
                                  <Amount
                                    amount={t.amount}
                                    color={
                                      t.type === "cash-in"
                                        ? "green900"
                                        : "red900"
                                    }
                                    fontWeight="semibold"
                                  />
                                </Box>
                                {checkIfAuthenticatedMemberCan(
                                  BOOK_PERMISSIONS.VIEW_NET_BALANCE
                                ) && (
                                  <Box
                                    as="td"
                                    paddingX="3"
                                    paddingY="4"
                                    textAlign="right"
                                    className="align-middle"
                                  >
                                    <Amount
                                      amount={
                                        closingBalancePerTransaction[t.id]
                                      }
                                    />
                                  </Box>
                                )}
                                <Box
                                  as="td"
                                  paddingX="3"
                                  paddingY="4"
                                  textAlign="right"
                                  position="relative"
                                  className="align-middle"
                                >
                                  {isTransactionUpdated(t) &&
                                  t.updatedAt &&
                                  t.updatedBy ? (
                                    <Box
                                      position="absolute"
                                      right="0"
                                      paddingRight="4"
                                      className="hover-child hide-on-hover z-[1]"
                                    >
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color="gray500"
                                        title={`Last updated by ${
                                          t.updatedBy === user.uid
                                            ? "You"
                                            : t.updatedByMember?.name
                                        } on ${formatDate(
                                          timeStampToDate(t.updatedAt),
                                          "dd MMM, yyyy 'at' hh:mm a"
                                        )}`}
                                      >
                                        (Edited)
                                      </Text>
                                    </Box>
                                  ) : null}
                                  <SuspenseWithPerf
                                    traceId="button"
                                    fallback="Actions"
                                  >
                                    {canUpdateTransactions ||
                                    canDeleteTransactions ? (
                                      <Inline
                                        gap="4"
                                        position="relative"
                                        className="hover-child z-[1]"
                                        onClick={(e: React.SyntheticEvent) => {
                                          e.stopPropagation()
                                        }}
                                      >
                                        {canDeleteTransactions ? (
                                          <Button
                                            inline
                                            onClick={() => {
                                              edit()
                                              trackEvent(
                                                TrackingEvents.EDIT_ENTRY_CLICKED,
                                                {
                                                  sharedBook: isShared,
                                                  billAttached: Boolean(
                                                    t.imageUrl
                                                  ),
                                                  role: role,
                                                  isEdited:
                                                    isTransactionUpdated(t),
                                                  synced: true,
                                                  from: "list",
                                                }
                                              )
                                            }}
                                            size="sm"
                                            className="border-transparent text-blue-900"
                                            title="Edit Entry"
                                          >
                                            <PencilIcon />
                                          </Button>
                                        ) : null}
                                        {canDeleteTransactions ? (
                                          <DeleteTransactions
                                            key={t.id}
                                            book={book}
                                          >
                                            {({ onDelete }) => (
                                              <Button
                                                onClick={() => onDelete(t)}
                                                size="sm"
                                                className="border-transparent text-yellow-600"
                                                title="Delete Entry"
                                                inline
                                                status="error"
                                              >
                                                <TrashIcon />
                                              </Button>
                                            )}
                                          </DeleteTransactions>
                                        ) : null}
                                      </Inline>
                                    ) : null}
                                  </SuspenseWithPerf>
                                </Box>
                              </Box>
                            )}
                          </EditTransactionInDialog>
                        )}
                      </TransactionDetailsInDialog>
                    </React.Fragment>
                  )
                })}
              </Box>
            </Box>
          </Stack>
        ) : totalTransactions && hasAppliedFilters ? (
          <Stack
            gap="10"
            alignItems="center"
            borderTopWidth="1"
            className="pt-16"
          >
            <Stack gap="4" alignItems="center">
              <Text fontSize="lg">
                No{params.type ? ` ${params.type}` : ``} entries added
                {resolvedDateIntervals.allTimeApplied
                  ? ""
                  : resolvedDateIntervals.customDateApplied
                  ? params.afterDate &&
                    params.beforeDate &&
                    isSameDay(params.afterDate, params.beforeDate)
                    ? ` on ${formatDate(params.afterDate, "dd MMM yy")}`
                    : ` between ${
                        params.afterDate
                          ? formatDate(params.afterDate, "dd MMM yy")
                          : "Start"
                      } - ${
                        params.beforeDate
                          ? formatDate(params.beforeDate, "dd MMM yy")
                          : "Now"
                      }`
                  : ` ${resolvedDateIntervals.appliedDateIntervalLabel}`.toLowerCase()}
                {params.entryBy ? ` by ${params.entryBy.name}` : ``}!
              </Text>
              {params.afterDate &&
              isBeforeDate(params.afterDate, new Date()) &&
              !isSameDay(params.afterDate, new Date()) ? (
                <Inline gap="2">
                  <InformationCircleIcon size="4" />
                  <Text>
                    You can add backdated entries using{" "}
                    <Text as="span" color="green900" fontWeight="semibold">
                      Cash In
                    </Text>{" "}
                    or{" "}
                    <Text as="span" color="red900" fontWeight="semibold">
                      Cash Out
                    </Text>
                  </Text>
                </Inline>
              ) : null}
            </Stack>
            <Box>
              <Button
                type="reset"
                level="primary"
                onClick={() => resetParams()}
              >
                Reset Filters
              </Button>
            </Box>
          </Stack>
        ) : (
          <Stack
            gap="4"
            alignItems="center"
            borderTopWidth="1"
            className="pt-16"
          >
            <Text fontSize="lg">No entries added Yet!</Text>
            {role === "viewer" ? null : (
              <Inline gap="2" alignItems="center">
                <InformationCircleIcon size="4" color="gray500" />
                <Text>
                  Use{" "}
                  <Text as="span" color="green900" fontWeight="semibold">
                    Cash In
                  </Text>{" "}
                  or{" "}
                  <Text as="span" color="red900" fontWeight="semibold">
                    Cash Out
                  </Text>{" "}
                  to add entries
                </Text>
              </Inline>
            )}
          </Stack>
        )}
      </Stack>
      <BookRolesAndPermissions
        role={role}
        bookId={book.id}
        hideNetBalance={book.preferences?.hideBalancesAndReports}
        hideEntriesByOthers={book.preferences?.hideEntriesByOthers}
      />
    </Stack>
  )
}

export function CashInCashOutButton({
  book,
  autoFocus: propAutoFocus,
  ...props
}: { book: TBook; autoFocus?: "in" | "out" | boolean } & Omit<
  React.ComponentProps<typeof LogTransactionInDialog>,
  "children" | "type"
>) {
  const { checkIfAuthenticatedMemberCan } = useBook(book.id)
  if (!checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_CASH_IN_OUT))
    return null
  let autoFocus: undefined | "in" | "out" = undefined
  switch (propAutoFocus) {
    case false:
    case undefined:
      autoFocus = undefined
      break
    // we default to 'in' v
    case true:
    case "in":
      autoFocus = "in"
      break
    case "out":
      autoFocus = "out"
      break
  }
  return (
    <Inline gap="6">
      <LogTransactionInDialog book={book} {...props}>
        {({ add }) => (
          <>
            <Tooltip
              event="onHover"
              content={
                <Box paddingBottom="1">
                  <Stack gap="2">
                    <Text>For Cash In</Text>
                    <Inline justifyContent="evenly">
                      <Box
                        className="bg-[#3A4060]"
                        rounded="md"
                        height="6"
                        width="6"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontSize="c2">C</Text>
                      </Box>
                      <Box
                        height="6"
                        width="6"
                        className="bg-[#3A4060]"
                        rounded="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontSize="c2">I</Text>
                      </Box>
                    </Inline>
                  </Stack>
                </Box>
              }
            >
              <Box className="w-[150px]">
                <Button
                  onClick={() => {
                    add("cash-in")
                    trackEvent(TrackingEvents.CASH_IN_CLICKED)
                  }}
                  status="success"
                  level="primary"
                  autoFocus={autoFocus === "in"}
                  fullWidth
                >
                  <PlusIcon size="6" /> Cash In
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              event="onHover"
              content={
                <Box paddingBottom="1">
                  <Stack gap="2">
                    <Text>For Cash Out</Text>
                    <Inline justifyContent="evenly">
                      <Box
                        className="bg-[#3A4060]"
                        rounded="md"
                        height="6"
                        width="6"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontSize="c2">C</Text>
                      </Box>
                      <Box
                        height="6"
                        width="6"
                        className="bg-[#3A4060]"
                        rounded="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontSize="c2">O</Text>
                      </Box>
                    </Inline>
                  </Stack>
                </Box>
              }
            >
              <Box className="w-[150px]">
                <Button
                  onClick={() => {
                    add("cash-out")
                    trackEvent(TrackingEvents.CASH_OUT_CLICKED)
                  }}
                  status="error"
                  level="primary"
                  autoFocus={autoFocus === "out"}
                  fullWidth
                >
                  <MinusIcon size="6" /> Cash Out
                </Button>
              </Box>
            </Tooltip>
          </>
        )}
      </LogTransactionInDialog>
    </Inline>
  )
}

const options_for_custom_date = [
  { id: "dateRange", label: "Date Range" },
  { id: "singleDay", label: "Single Day" },
]
function SelectCustomDate({
  wasCustomApplied,
  appliedCustomFilterType,
  isOpeningBalanceEnabled,
  value: [afterDate, beforeDate],
  onChange,
  children,
}: {
  wasCustomApplied?: boolean
  appliedCustomFilterType?: "dateRange" | "singleDay"
  isOpeningBalanceEnabled?: boolean
  value: [Date | undefined, Date | undefined]
  onChange: (
    dates: [Date | undefined, Date | undefined],
    isEnabled?: boolean
  ) => void
  children: (props: { onSelect: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [localeAfterDate, setAfterDate] = useState<Date | undefined>(undefined)
  const [localeBeforeDate, setBeforeDate] = useState<Date | undefined>(
    undefined
  )
  const [localOpeningBalanceInclusion, setLocalOpeningBalanceInclusion] =
    useState<boolean | undefined>(isOpeningBalanceEnabled)
  const [dateValidationError, setDateValidationError] = useState<
    { startDate?: string; endDate?: string } | undefined
  >(undefined)

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    appliedCustomFilterType
  )

  useEffect(() => {
    if (wasCustomApplied) {
      setAfterDate(afterDate)
      setBeforeDate(beforeDate)
    } else {
      setAfterDate(undefined)
      setBeforeDate(undefined)
      setDateValidationError(undefined)
      setSelectedOption("dateRange")
    }
  }, [afterDate, beforeDate, wasCustomApplied])

  const onClose = () => {
    if (!wasCustomApplied) {
      setAfterDate(undefined)
      setBeforeDate(undefined)
    }
    setSelectedOption(appliedCustomFilterType)
    setDateValidationError(undefined)
    state.close()
  }

  const onSave = () => {
    if (selectedOption === "singleDay") {
      if (!localeAfterDate) {
        setDateValidationError({ startDate: "Please select a valid date!" })
        return
      }
      onChange(
        [localeAfterDate, localeBeforeDate],
        localOpeningBalanceInclusion
      )
    } else {
      if (!localeAfterDate || !localeBeforeDate) {
        let error = {}
        if (!localeAfterDate) {
          error = { ...error, startDate: "Select start date" }
        }
        if (!localeBeforeDate) {
          error = { ...error, endDate: "Select end date" }
        }
        setDateValidationError(error)
        return
      }
      onChange(
        [localeAfterDate, localeBeforeDate],
        localOpeningBalanceInclusion
      )
    }
    setDateValidationError(undefined)
    state.close()
  }

  return (
    <>
      {children({ onSelect: state.open })}
      <Modal
        title="Select Custom Date"
        isOpen={state.isOpen}
        onClose={onClose}
        size="sm"
        isDismissable
      >
        <ModalBody autoMaxHeight>
          <Stack gap="8">
            <Inline gap="4" as="ul">
              {options_for_custom_date.map((option) => (
                <Box
                  as="li"
                  key={option.id}
                  borderColor="borderOutline"
                  paddingY="2"
                  paddingX="4"
                  borderWidth={selectedOption === option.id ? undefined : "1"}
                  rounded="md"
                  cursor="pointer"
                  backgroundColor={
                    selectedOption === option.id
                      ? "surfacePrimaryLowest"
                      : undefined
                  }
                  onClick={() => {
                    setDateValidationError(undefined)
                    setSelectedOption(option.id)
                  }}
                >
                  <Text
                    fontSize="b3"
                    color={
                      selectedOption === option.id ? "textPrimary" : undefined
                    }
                  >
                    {option.label}
                  </Text>
                </Box>
              ))}
            </Inline>
            {selectedOption === "singleDay" ? (
              <Stack flex="1" gap="1">
                <Text fontSize="c2">Date</Text>
                <DatePicker
                  value={localeAfterDate}
                  placeholder="DD MM YYYY"
                  onChange={(date) => {
                    setDateValidationError(undefined)
                    setAfterDate(date ? startOfDay(date) : undefined)
                    setBeforeDate(date ? endOfDay(date) : undefined)
                  }}
                  inputProps={{
                    error: dateValidationError?.startDate,
                    className:
                      "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                  }}
                />
              </Stack>
            ) : (
              <Inline gap="8">
                <Stack flex="1" gap="1">
                  <Text fontSize="c2">Start Date</Text>
                  <DatePicker
                    placeholder="DD MM YYYY"
                    value={localeAfterDate}
                    onChange={(date) => {
                      setDateValidationError((prevState) => {
                        return { ...prevState, startDate: undefined }
                      })
                      setAfterDate(date ? startOfDay(date) : undefined)
                    }}
                    max={localeBeforeDate}
                    inputProps={{
                      error: dateValidationError?.startDate,
                      className:
                        "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                    }}
                  />
                </Stack>
                <Stack flex="1" gap="1">
                  <Text fontSize="c2">End Date</Text>
                  <DatePicker
                    value={localeBeforeDate}
                    onChange={(date) => {
                      setDateValidationError((prevState) => {
                        return { ...prevState, endDate: undefined }
                      })
                      setBeforeDate(date ? endOfDay(date) : undefined)
                    }}
                    min={localeAfterDate}
                    inputProps={{
                      error: dateValidationError?.endDate,
                      className:
                        "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                    }}
                  />
                </Stack>
              </Inline>
            )}

            <Box
              backgroundColor={
                localOpeningBalanceInclusion
                  ? "surfacePrimaryLowest"
                  : "surfaceNeutralLowest"
              }
              paddingY="3"
              paddingX="4"
              rounded="md"
            >
              <Inline
                gap="2"
                onClick={() =>
                  setLocalOpeningBalanceInclusion((prevState) => !prevState)
                }
                cursor="pointer"
              >
                <Box
                  as="input"
                  type="checkbox"
                  name="isOpeningBalance"
                  checked={localOpeningBalanceInclusion}
                  readOnly={Boolean(isOpeningBalanceEnabled)}
                />
                <Text fontSize="c2" className="px-1">
                  Include opening balance
                </Text>
              </Inline>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSave} level="primary" size="lg">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function Overview({
  totalCashIn,
  totalCashOut,
  closingBalance,
  openingBalance,
}: {
  totalCashIn: number
  totalCashOut: number
  closingBalance: number
  openingBalance?: number
}) {
  return (
    <Stack gap="2" aria-label="Overview">
      <Box borderWidth="1" rounded="md" paddingY="4">
        <Inline>
          {openingBalance !== undefined ? (
            <Box flex="1" borderRightWidth="1" paddingX="6">
              <OverviewItem
                icon={
                  <OverviewItemIcon bgColor="blue100" color="blue900">
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.894 10.14C4.89533 10.14 3.99467 9.92533 3.192 9.496C2.38933 9.05733 1.75933 8.45533 1.302 7.69C0.844667 6.91533 0.616 6.052 0.616 5.1C0.616 4.148 0.844667 3.28933 1.302 2.524C1.75933 1.74933 2.38933 1.14733 3.192 0.717999C3.99467 0.279333 4.89533 0.0599995 5.894 0.0599995C6.89267 0.0599995 7.79333 0.279333 8.596 0.717999C9.39867 1.14733 10.0287 1.74467 10.486 2.51C10.9433 3.27533 11.172 4.13867 11.172 5.1C11.172 6.06133 10.9433 6.92467 10.486 7.69C10.0287 8.45533 9.39867 9.05733 8.596 9.496C7.79333 9.92533 6.89267 10.14 5.894 10.14ZM5.894 8.544C6.54733 8.544 7.13533 8.39933 7.658 8.11C8.18067 7.81133 8.59133 7.40067 8.89 6.878C9.18867 6.346 9.338 5.75333 9.338 5.1C9.338 4.44667 9.18867 3.85867 8.89 3.336C8.59133 2.804 8.18067 2.39333 7.658 2.104C7.13533 1.80533 6.54733 1.656 5.894 1.656C5.24067 1.656 4.65267 1.80533 4.13 2.104C3.60733 2.39333 3.19667 2.804 2.898 3.336C2.59933 3.85867 2.45 4.44667 2.45 5.1C2.45 5.75333 2.59933 6.346 2.898 6.878C3.19667 7.40067 3.60733 7.81133 4.13 8.11C4.65267 8.39933 5.24067 8.544 5.894 8.544Z" />
                    </svg>
                  </OverviewItemIcon>
                }
                label="Opening Balance"
                amount={openingBalance}
                description={
                  <Stack gap="4">
                    <Text>
                      The opening balance is the balance that is brought forward
                      from the end of one accounting period to the beginning of
                      a new accounting period
                    </Text>
                    <Text>
                      When you apply Today, Month or any date filter, opening
                      balance is automatically applied.
                    </Text>
                  </Stack>
                }
              />
            </Box>
          ) : null}
          <Box paddingX="6" borderRightWidth="1" flex="1">
            <OverviewItem
              icon={
                <OverviewItemIcon bgColor="green100" color="green900">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5 11V7H1C0.734784 7 0.480505 6.89457 0.292969 6.70703C0.105432 6.5195 0 6.26522 0 6C0 5.73478 0.105432 5.4805 0.292969 5.29297C0.480505 5.10543 0.734784 5 1 5H5V1C5 0.734784 5.10543 0.480505 5.29297 0.292969C5.4805 0.105432 5.73478 0 6 0C6.26522 0 6.5195 0.105432 6.70703 0.292969C6.89457 0.480505 7 0.734784 7 1V5H11C11.2652 5 11.5195 5.10543 11.707 5.29297C11.8946 5.4805 12 5.73478 12 6C12 6.26522 11.8946 6.5195 11.707 6.70703C11.5195 6.89457 11.2652 7 11 7H7V11C7 11.2652 6.89457 11.5195 6.70703 11.707C6.5195 11.8946 6.26522 12 6 12C5.73478 12 5.4805 11.8946 5.29297 11.707C5.10543 11.5195 5 11.2652 5 11Z" />
                  </svg>
                </OverviewItemIcon>
              }
              label="Cash In"
              amount={totalCashIn}
            />
          </Box>
          <Box paddingX="6" flex="1" borderRightWidth="1">
            <OverviewItem
              icon={
                <OverviewItemIcon bgColor="red100" color="red900">
                  <svg
                    width="12"
                    height="2"
                    viewBox="0 0 12 2"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 1C12 1.26522 11.8946 1.51949 11.707 1.70703C11.5195 1.89457 11.2652 2 11 2H1C0.734784 2 0.480505 1.89457 0.292969 1.70703C0.105432 1.51949 0 1.26522 0 1C0 0.734784 0.105432 0.480505 0.292969 0.292969C0.480505 0.105432 0.734784 0 1 0H11C11.2652 0 11.5195 0.105432 11.707 0.292969C11.8946 0.480505 12 0.734784 12 1Z" />
                  </svg>
                </OverviewItemIcon>
              }
              label="Cash Out"
              amount={totalCashOut}
            />
          </Box>
          <Box paddingX="6" flex="1">
            <OverviewItem
              icon={
                <OverviewItemIcon bgColor="blue100" color="blue900">
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 1C12 1.26522 11.8946 1.51949 11.707 1.70703C11.5195 1.89457 11.2652 2 11 2H1C0.734784 2 0.480505 1.89457 0.292969 1.70703C0.105432 1.51949 0 1.26522 0 1C0 0.734784 0.105432 0.480505 0.292969 0.292969C0.480505 0.105432 0.734784 0 1 0H11C11.2652 0 11.5195 0.105432 11.707 0.292969C11.8946 0.480505 12 0.734784 12 1Z" />
                    <path d="M12 6C12 6.26522 11.8946 6.51949 11.707 6.70703C11.5195 6.89457 11.2652 7 11 7H1C0.734784 7 0.480505 6.89457 0.292969 6.70703C0.105432 6.51949 0 6.26522 0 6C0 5.73478 0.105432 5.48051 0.292969 5.29297C0.480505 5.10543 0.734784 5 1 5H11C11.2652 5 11.5195 5.10543 11.707 5.29297C11.8946 5.48051 12 5.73478 12 6Z" />
                  </svg>
                </OverviewItemIcon>
              }
              label="Net Balance"
              amount={closingBalance}
            />
          </Box>
        </Inline>
      </Box>
    </Stack>
  )
}

function OverviewItem({
  label,
  amount,
  icon,
  description,
}: {
  label: React.ReactNode
  amount: number
  icon: React.ReactNode
  description?: React.ReactNode
}) {
  return (
    <Inline gap="3" className="font-semibold">
      <Box>{icon}</Box>
      <Stack gap="1">
        <Inline gap="2">
          <Text fontSize="sm">{label}</Text>
          {description ? (
            <Box>
              <Tooltip
                event="onClick"
                content={<Box className="w-60">{description}</Box>}
              >
                <InformationCircleIcon size="4" />
              </Tooltip>
            </Box>
          ) : null}
        </Inline>
        <Box fontSize="lg">
          <Amount amount={amount} />
        </Box>
      </Stack>
    </Inline>
  )
}

function OverviewItemIcon(props: React.ComponentProps<typeof Stack>) {
  return (
    <Stack
      size="6"
      rounded="full"
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  )
}

function OptionsOnSelection({
  partyLabel,
  canCopyEntries,
  canCopyOpposite,
  canMoveEntries,
  canDeleteEntries,
  canEditTransaction,
  numberOfSelectedEntries,
  onCopy,
  onMove,
  onDelete,
  onSelectAll,
  onCopyOpposite,
  onChangeFieldsFor,
}: {
  partyLabel: string
  canMoveEntries?: boolean
  canCopyEntries?: boolean
  canCopyOpposite?: boolean
  canDeleteEntries?: boolean
  canEditTransaction?: boolean
  numberOfSelectedEntries: number
  onCopy: () => void
  onMove: () => void
  onDelete: () => void
  onSelectAll: () => void
  onCopyOpposite: () => void
  onChangeFieldsFor: (type: "category" | "parties" | "paymentMode") => void
}) {
  return (
    <Inline
      alignItems="center"
      paddingLeft="3"
      width="full"
      height="full"
      fontSize="c1"
      gap="4"
      color="textHigh"
    >
      <Box cursor="pointer" onClick={onSelectAll}>
        <Text fontSize="c2">Select All</Text>
      </Box>
      {canDeleteEntries ? (
        <>
          <Box
            className="w-[1px]"
            backgroundColor="borderPrimaryLow"
            height="6"
          />
          <Inline
            alignItems="center"
            gap="2"
            cursor="pointer"
            paddingLeft="2"
            paddingRight="3"
            paddingY="1"
            rounded="md"
            onClick={onDelete}
            className={`hover:bg-[#EDEFFB]`}
          >
            <TrashIcon color="iconError" size="5" />
            <Text>Delete</Text>
          </Inline>
        </>
      ) : null}
      {canCopyOpposite || canCopyEntries || canMoveEntries ? (
        <>
          <Box
            className="w-[1px]"
            backgroundColor="borderPrimaryLow"
            height="6"
          />
          <Menu>
            <MenuButton inline>
              <Inline
                gap="2"
                alignItems="center"
                cursor="pointer"
                paddingX="2"
                paddingY="1"
                rounded="md"
                className={`hover:bg-[#EDEFFB]`}
              >
                <MoveIcon size="5" color="iconPrimary" />
                <Inline alignItems="center" gap="1">
                  <Text>Move or Copy</Text>
                  <ArrowDropDownIcon size="5" color="iconMedium" />
                </Inline>
              </Inline>
            </MenuButton>
            <MenuList>
              {canMoveEntries ? (
                <MenuItem action="move" className="py-3 my-2" onClick={onMove}>
                  <Inline alignItems="center" gap="3">
                    <MoveIcon size="5" color="iconMedium" />
                    <Text fontSize="b3">
                      Move {pluralize("Entry", numberOfSelectedEntries)}
                    </Text>
                  </Inline>
                </MenuItem>
              ) : null}
              {canCopyEntries ? (
                <MenuItem action="copy" className="py-3 my-2" onClick={onCopy}>
                  <Inline alignItems="center" gap="3">
                    <CopyIcon size="5" color="iconMedium" />
                    <Text fontSize="b3">
                      Copy {pluralize("Entry", numberOfSelectedEntries)}
                    </Text>
                  </Inline>
                </MenuItem>
              ) : null}
              {canCopyOpposite ? (
                <MenuItem
                  action="opposite"
                  className="py-3 my-2"
                  onClick={onCopyOpposite}
                >
                  <Inline alignItems="center" gap="3">
                    <PlusMinus size="5" color="iconMedium" />
                    <Text fontSize="b3">
                      Copy Opposite{" "}
                      {pluralize("Entry", numberOfSelectedEntries)}
                    </Text>
                  </Inline>
                </MenuItem>
              ) : null}
            </MenuList>
          </Menu>
        </>
      ) : null}
      {canEditTransaction ? (
        <>
          <Box
            className="w-[1px]"
            backgroundColor="borderPrimaryLow"
            height="6"
          />
          <Menu>
            <MenuButton inline>
              <Inline
                gap="2"
                alignItems="center"
                cursor="pointer"
                paddingLeft="2"
                paddingRight="3"
                paddingY="1"
                rounded="md"
                className={`hover:bg-[#EDEFFB]`}
              >
                <ExchangeIcon size="5" color="iconPrimary" />
                <Inline alignItems="center" gap="1">
                  <Text>Change Fields</Text>
                  <ArrowDropDownIcon size="5" color="iconMedium" />
                </Inline>
              </Inline>
            </MenuButton>
            <MenuList>
              <MenuItem
                action="move"
                className="py-3 my-2"
                onClick={() => onChangeFieldsFor("category")}
              >
                <Inline alignItems="center" gap="3">
                  <CategoryIcon size="5" color="iconMedium" />
                  <Text fontSize="b3">Change Category</Text>
                </Inline>
              </MenuItem>
              <MenuItem
                action="copy"
                className="py-3 my-2"
                onClick={() => onChangeFieldsFor("paymentMode")}
              >
                <Inline alignItems="center" gap="3">
                  <PaymentModeIcon size="5" color="iconMedium" />
                  <Text fontSize="b3">Change Payment Mode</Text>
                </Inline>
              </MenuItem>
              <MenuItem
                action="opposite"
                className="py-3 my-2"
                onClick={() => onChangeFieldsFor("parties")}
              >
                <Inline alignItems="center" gap="3">
                  <UsersIcon size="5" color="iconMedium" />
                  <Text fontSize="b3">Change {partyLabel}</Text>
                </Inline>
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      ) : null}
    </Inline>
  )
}
